import React from 'react'
import TransactionOverview from './TransactionOverview';
import Requests from './Requests'; 
import './CSS/illustration.css' 
const Illustration = () => {
  return (
    <div className='illustrationContainer'>
        <TransactionOverview />
        <Requests /> 
    </div>
  )
}

export default Illustration
