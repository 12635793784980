import React, { useState, useCallback } from 'react' 
import './CSS/invitesharelink.css' 
import { Formik, Form } from 'formik'  
import * as YUP from 'yup' 
import TextInput from '../components/TextInput'   
import Button from './Button'  
import moment from 'moment'   
import { shareReferralLinkByEmail } from '../security/AuthService'
import ShareSuccess from '../Modals/ShareSuccess' 
// import ShareFailed from '../Modals/ShareFailed' 
const InviteShareLink = ({referralCode, codeExpirationDate}) => {
    const [emailAlreadyExist, setEmailAlreadyExist] = useState(false) 
    const [isCopied, setIsCopied] = useState(null)
    const [isShareSuccess, setIsShareSuccess] = useState(false)   
    // const [isShareFailed, setIsShareFailed] = useState(false) 
    const showSuccess = useCallback(function () {
        setIsShareSuccess(true)
    }, []) 
    const fadeSuccess = useCallback(function () {
        setIsShareSuccess(false)
    }, []) 
    // const showFailure = useCallback(function () {
    //     setIsShareFailed(true) 
    // }, [])
    // const fadeFailure = useCallback(function () {
    //     setIsShareFailed(false) 
    // }, []) 
    const initializeEmailAlreadyExist = useCallback(function () {
        setEmailAlreadyExist(false) 
    }, [])
    const handleEmailAlreadyExist = useCallback(function () {
        setEmailAlreadyExist(true) 
    }, []) 
    async function copyReferralCode () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('referral_invitation_code').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
            setIsCopied(false)
        }
    }
    const shareReferralLink = useCallback(async function() {
        try {
            const referralLink = new URL(window.location.href).origin + `/register?invitationCode=${referralCode}`

            let shareData = { 
                title: 'Join and Earn Rewards!', 
                text: 'Sign up to explore Paytaps and enjoy seamless crypto spending with just a tap. You’ll be able to order your Paytaps card and get started right away.',   
                url: referralLink, 
            } 

            if (!navigator.canShare) {
                await navigator.clipboard.writeText(referralLink)
            } else if (navigator.canShare(shareData)) {
                await navigator.share(shareData); 
            } else {
                await navigator.clipboard.writeText(referralLink) 
            } 
 

        } catch(err) {  
        } 
    }, [referralCode])  

  return (  
    <>
        <div className='inviteShareLinkContainer'>
            <div>
                <div className='inviteShareLinkTitle'>Invite a Friend and Earn Rewards</div>
                <div className='inviteShareLinkSubTitle'>Use the options below to share your referral link</div> 

                <Formik
                            initialValues={{
                                invitee_email: ''
                            }}

                            validationSchema={YUP.object({
                                invitee_email: YUP.string().email('Invalid email!') 
                            })}

                            onSubmit={async (values, {setSubmitting, resetForm, setFieldValue, setFieldTouched})=>{
                                    try {
                                        const referralLink = new URL(window.location.href).origin + `/register?invitationCode=${referralCode}`
                                        const post_link = await shareReferralLinkByEmail({ link: referralLink, email: values.invitee_email})                                                                                                                                                                                                                                                                     
                                        const result_post_link = await post_link.json()
                                        
                                        if(post_link.status === 200 && result_post_link['message'] === 'Success') {
                                            showSuccess()
                                            resetForm()  
                                            setFieldValue('invitee_email', '')    
                                            setFieldTouched('invitee_email')
                                        }
                                        if(post_link.status === 400 && result_post_link['message'] === 'Email Exist') {
                                            handleEmailAlreadyExist() 
                                            showSuccess() 
                                            resetForm()  
                                            setFieldValue('invitee_email', '')    
                                            setFieldTouched('invitee_email')
                                        }
                                        // if(post_link.status === 400 && result_post_link['message'] === 'Invalid data') {  
                                        //     showFailure() 
                                        // }
                                        // if(post_link.status === 500 && result_post_link['error'] === 'Failed Send Email') {
                                        //     showFailure() 
                                        // }
                                          
                                    } catch(err) {

                                    }
                            }}
                        >
                            {(formik) => {
                            return (
                                <Form className='form inviteFriend' onSubmit={formik.handleSubmit}>
                                    <TextInput name='invitee_email' id='invitee_email' label=''  styles='sendInvitationByEmailInput' />
                                    <button type='submit' id='sendInvitationToFriend' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}><img src='/images/dashboard/referral/Group 466.svg' alt='a blue circle inside it a white send symbol' /><span className='sendInvite'>Send invitation</span></button>   
                                </Form>)}}
                </Formik>

                <div className='shareYourLink'>Share Your Link</div>
                <p className='referralLinkGeneration'>Your unique referral link has been generated and is available for the next <span>{moment(codeExpirationDate).diff(moment(), 'd')} days</span>, until <span>{moment(codeExpirationDate).format('MMMM DD, YYYY')}</span>. Share it with friends to invite them and earn rewards when they order their card.</p> 
                <div className='generatedCodeCopyWrapper'>
                    <span className='generatedCode' id='referral_invitation_code'>{referralCode}</span> <div className='copyShareBtnWrapper'><Button imagesrc='/images/dashboard/referral/mingcute_copy-line.svg' styles='copyInvitationCode' click={copyReferralCode}>{isCopied ? 'Copied!' : 'copy'}</Button> <Button imagesrc='/images/dashboard/referral/Group 464.svg' styles='shareBtn' click={shareReferralLink}><span className='shareLabel'>Share</span></Button></div>                                                                                                                                                     
                    {/* <button type='button' id='sendWithArrowToRight'><img src='/images/dashboard/referral/Group 464.svg' alt='a blue circle inside it an arrow oriented to the right' /> </button>  */}
                </div>
            </div>
        </div>
        <ShareSuccess show={isShareSuccess} fadeSuccess={fadeSuccess} isEmailALreadyExist={emailAlreadyExist} initializeEmailAlreadyExist={initializeEmailAlreadyExist} />       
        {/* <ShareFailed show={isShareFailed} fadeFailure={fadeFailure} />               */}
    </>
  )
   
}

export default InviteShareLink  

