import React from 'react'
import './CSS/searcharea.css' 
import Button from './Button'
const SearchArea = ({changeFilterInputValueHandler, resetFilterInputValueHandler}) => {
  return (
    <div className='searchAreaContainer'>
        <input type='text' name='filterByName' id='filterCardTrxByName' placeholder='Search' onChange={changeFilterInputValueHandler}/> 
        <img src='/images/dashboard/mycard/tabler_zoom.svg' alt='a magnifying glass with black border' className='mgStyles' />
        <Button imagesrc='/images/dashboard/mycard/tabler_x.svg' altText='a cross mark with black border' styles='cmStyles' click={resetFilterInputValueHandler} />         
    </div> 
  )
}

export default SearchArea