import React from 'react'
import './CSS/pagination.css' 

const Pagination = ({data, paginationHandler, searchParams}) => {
  const current = parseInt(searchParams.get('p'))
  
  
  return (
    <div className='paginationContainer'>
        {(data.length < 10) && (
          data.map((item, index) => (
            <div 
              key={`page-item-${index}`} 
              className={`paginationItem ${parseInt(searchParams.get('p')) === (index + 1) ? 'paginationItemActive' : ''}`} 
              onClick={paginationHandler}>
              {index + 1}
            </div>
          ))
        )}
     
        {(data.length >= 10 && current >= 6) && (
          data.map((item, index) => (
            <React.Fragment key={`page-fragment-${index}`}>
              {(index === 0 || index === 1) && (
                <div 
                  key={`first-item-${index}`} 
                  className={`paginationItem ${parseInt(searchParams.get('p')) === (index + 1) ? 'paginationItemActive' : ''}`} 
                  onClick={paginationHandler}>
                  {index + 1}
                </div>
              )}
    
              {(index >= current - 3 && index <= current + 1) && (
                <div 
                  key={`near-item-${index}`} 
                  className={`paginationItem ${current === (index + 1) ? 'paginationItemActive' : ''}`} 
                  onClick={paginationHandler}>
                  {index + 1}
                </div>
              )}
    
              {((index === current - 4) || index === (current + 2)) && (
                <div key={`dots-item-${index}`} className="paginationItem" style={{cursor: 'auto'}}>..</div>
              )}
            </React.Fragment>
          ))
        )}
     
        {(data.length >= 10 && current < 6) && (
          <>
            {data.slice(0, 6).map((item, index) => (
              <div 
                key={`page-start-${index}`} 
                className={`paginationItem ${parseInt(searchParams.get('p')) === (index + 1) ? 'paginationItemActive' : ''}`} 
                onClick={paginationHandler}>
                {index + 1}
              </div>
            ))}
            <div key="dots-end" className="paginationItem" style={{cursor: 'auto'}}>..</div>
          </>
        )}

    </div>
    );
} 
    export default Pagination   
  