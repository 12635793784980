import React, { useEffect } from 'react'
import {Helmet} from "react-helmet";
import './CSS/contact.css' 
import { findRequestCard } from '../security/AuthService';
import { useReferral } from '../context/ReferralContext';
const Contact = () => {
  const { showReferral, handleShowReferral } = useReferral() 

    useEffect(() => { 
        const script = document.createElement("script"); 
        script.src = "https://js.hsforms.net/forms/v2.js"; 
        document.body.appendChild(script); 
     
        script.onload = () => { 
          if (window.hbspt) { 
            window.hbspt.forms.create({ 
              portalId: "5056029", 
              formId: "a5e6c62d-00bc-4182-ba51-6c9af5d18766", 
              target: "#hubspotForm" 
            }); 
          } 
        }; 
      }, []); 

      useEffect(()=>{
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['req'] !== 'S') {
        findCardRequest()
        } else {
          if(!showReferral) {
            handleShowReferral() 
          } 
        }
      }, []) 

      async function findCardRequest () {
        const fetch_card_request = await findRequestCard()             
        const result_fetch_card_request = await fetch_card_request.json() 

        if(result_fetch_card_request['data'].length > 0) {
            if(result_fetch_card_request['data'][0]['requesteStatus'] === 'Success') {
                handleShowReferral() 
            }
        }
      }


      return ( 
        <div className='hsContainer'> 
          <Helmet> 
            <script src="https://js.hsforms.net/forms/v2.js" type="text/javascript" /> 
          </Helmet> 
          <div id="hubspotForm"></div> 
        </div> 
      ); 
     
}
export default Contact
 
