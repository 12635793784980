// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginationContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap; 
    justify-content: flex-end; 
    position: relative;
    top: 15px; 
    width: 100%;
    padding-inline: 15px; 
}
.paginationItem {
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid var(--color-1EAAE7) !important;
    font-size: 15px;
    line-height: 24px;
    color: #898989;
    cursor: pointer; 
}
.paginationItemActive {
    background: var(--color-1EAAE7) !important; 
    color: var(--color-FFFFFF) !important;
}

@media screen and (max-width: 640px) {
    .paginationContainer {
        width: 700px;
        justify-content: start; 
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gDAAgD;IAChD,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;AACA;IACI,0CAA0C;IAC1C,qCAAqC;AACzC;;AAEA;IACI;QACI,YAAY;QACZ,sBAAsB;IAC1B;AACJ","sourcesContent":[".paginationContainer {\n    display: flex;\n    gap: 10px;\n    flex-wrap: wrap; \n    justify-content: flex-end; \n    position: relative;\n    top: 15px; \n    width: 100%;\n    padding-inline: 15px; \n}\n.paginationItem {\n    width: 24px;\n    height: 24px;\n    text-align: center;\n    border-radius: 50%;\n    border: 1px solid var(--color-1EAAE7) !important;\n    font-size: 15px;\n    line-height: 24px;\n    color: #898989;\n    cursor: pointer; \n}\n.paginationItemActive {\n    background: var(--color-1EAAE7) !important; \n    color: var(--color-FFFFFF) !important;\n}\n\n@media screen and (max-width: 640px) {\n    .paginationContainer {\n        width: 700px;\n        justify-content: start; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
