import React, { useState, useEffect } from 'react'
import './CSS/requests.css' 
import Donut from '../jsx/components/Mophy/Dashboard/Donut'  
import Loader from '../jsx/pages/Loader/Loader'
import { getTopupRequestsMetrics, getDepositRequestsMetrics } from '../security/AuthService' 
const Requests = () => {
    const [loadingState, setLoadingState] = useState(true)  
    const [metricsData, setMetricsData] = useState({
        allDeposits: 0,
        pendingDeposit: 0,
        successfulDeposit: 0,
        allTopup: 0,
        pendingTopup: 0, 
        successfulTopup: 0
    })

    useEffect(()=>{
        getPendingDepositTopupMetrics()
    }, []) 

    async function getPendingDepositTopupMetrics () { 
        try {
            const fetch_topup_requests_metrics = await getTopupRequestsMetrics()
            const result_fetch_topup_requests_metrics = await fetch_topup_requests_metrics.json() 
            const fetch_deposit_requests_metrcis = await getDepositRequestsMetrics()
            const result_fetch_deposit_requests_metrcis = await fetch_deposit_requests_metrcis.json()
            if((fetch_topup_requests_metrics.status === 200 && result_fetch_topup_requests_metrics['message'] === 'success') && (fetch_deposit_requests_metrcis.status === 200 && result_fetch_deposit_requests_metrcis['message'] === 'success')) {
                setMetricsData({
                    ...metricsData,
                    allDeposits: result_fetch_deposit_requests_metrcis['data']['depositRequestAll'],
                    pendingDeposit: result_fetch_deposit_requests_metrcis['data']['depositRequestPending'],
                    successfulDeposit: result_fetch_deposit_requests_metrcis['data']['depositRequestSuccess'], 
                    allTopup: result_fetch_topup_requests_metrics['data']['topupRequestAll'],
                    pendingTopup: result_fetch_topup_requests_metrics['data']['topupRequestPending'],
                    successfulTopup: result_fetch_topup_requests_metrics['data']['topupRequestSuccess']
                })

            }
        } catch(err) { 

        }
        finally {
            setLoadingState(false) 
        }
    }
    
  return (
    <div className='requestsContainer'>
        {!loadingState && <>
        <div className='requestsTitle'>Request</div> 

        <div className='pendingRequestsWrapper'>

            <div className='pendingRequestDataItemWrapper'> 
            <svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#461EE7"/>
            </svg>
                <span className='pendingRequestData'>                                                                                                             
                    <span className='pendingRequestDataLabel'>Pending deposit request</span>
                    <span className='pendingRequestDataValues'>
                    <span>{metricsData.pendingDeposit}</span><span>/{metricsData.allDeposits}</span>   
                    </span>
                </span>
            </div>
            <div className='pendingRequestDataItemWrapper'>
                <svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#40D4A8"/>
                </svg>
                <span className='pendingRequestData'>
                    <span className='pendingRequestDataLabel'>Pending top-up request</span> 
                    <span className='pendingRequestDataValues'>
                        <span>{metricsData.pendingTopup}</span><span>/{metricsData.allTopup}</span>  
                    </span> 
                </span>
            </div> 
        </div> 

        <div className='pendingRequestsIllustrationsWrapper'>
            <div className="bg-info rounded text-center p-3">
                <div className="d-inline-block position-relative donut-chart-sale">
                    <Donut value={metricsData.allDeposits > 0 ? ((metricsData.successfulDeposit * 100) / metricsData.allDeposits) : 0} backgroundColor="rgb(255, 255, 255)" backgroundColor2="rgba(0,0,0,0.1)"/>
                    <span className="text-white illustrationValue">{metricsData.allDeposits > 0 ? ((metricsData.successfulDeposit * 100) / metricsData.allDeposits).toFixed(1) : 0}%</span>  
                </div>
                <span className="dataTextualLabel text-white d-block">Successful deposit request</span>
            </div>
            <div className="bg-success rounded text-center p-3">
                <div className="d-inline-block position-relative donut-chart-sale">
                    <Donut value={metricsData.allTopup > 0 ? ((metricsData.successfulTopup * 100) / metricsData.allTopup) : 0} backgroundColor="rgb(255, 255, 255)" backgroundColor2="rgba(0,0,0,0.1)"/>
                    <span className="text-white illustrationValue">{metricsData.allTopup > 0 ? ((metricsData.successfulTopup * 100) / metricsData.allTopup).toFixed(1) : 0}%</span>   
                </div>
                <span className="dataTextualLabel text-white d-block">Successful top-up request</span>                                                            
            </div>
        </div>  
        
        </>} 
        {loadingState && <div className='waitLoadingProfileData h-50vh'><div>Please wait...</div><Loader /></div>} 
    </div>
  )
}

export default Requests
