import React from 'react'
import PaytabsMasterCard from './components/PaytabsMasterCard'
import Cardfeatures from './components/Cardfeatures'
import ProductOverview from './components/ProductOverview'
import Howitworks from './components/Howitworks'
import Benefits from './components/Benefits'
import SecureTrusted from './components/SecureTrusted'
import CardDetails from './components/CardDetails'
import Testimonial from './components/Testimonial'
import Experience from './components/Experience'
import FAQ from './components/FAQ' 
import Video from './components/Video'
const LpBody = () => {
  return (
    <>
          <PaytabsMasterCard />
          <Video />  
          <Cardfeatures />
          <ProductOverview />
          <Howitworks />
          <Benefits />
          <SecureTrusted />
          <CardDetails />
          <Testimonial />
          <FAQ /> 
          <Experience /> 
    </>
  )
}

export default LpBody