import React, { useState, useEffect, useRef } from 'react'
import './CSS/selectinput.css'
import { Field } from 'formik'
import { ErrorMessage } from 'formik'
import ErrorInput from './ErrorInput'
const SelectInput = ({label, id, name, options, firstOption, valueSelect}) => {
  const [inside, setInside] = useState(false)
  const [outside, setOutside] = useState(false)

  useEffect(()=>{
    document.getElementById(id).style.color = '#7e7e7e'
    document.getElementById(id).style.opacity = '0.8'
    document.addEventListener("click", function(evt) {
      let flyoutEl = document.getElementById(id),
        targetEl = evt.target; // clicked element      
        if(targetEl == flyoutEl) {
          setInside(true)
          setOutside(false)
        } else {
          setInside(false)
          setOutside(true)
        }
    })
    
    if((document.getElementById(id).selectedOptions[0].innerText !== firstOption)  ) {
      document.getElementById(id).style.color = '#000000'
      document.getElementById(id).style.opacity = '1'
    } 

    if((document.getElementById(id).selectedOptions[0].innerText === firstOption) && inside && !outside) {
      document.getElementById(id).style.color = '#000000' 
      document.getElementById(id).style.opacity = '1' 
    }

    if((document.getElementById(id).selectedOptions[0].innerText === firstOption) && outside && !inside ) {
      document.getElementById(id).style.color = '7e7e7e'  
      document.getElementById(id).style.opacity = '0.8'
    }

  })
    
  return (
    <>
        <div className='form_control'>
            <label htmlFor={id} className='form_label'>{label}</label>
            <Field as='select' id={id} name={name} className='inputForm' >
                <option value='' disabled key={firstOption}>{firstOption}</option>
                {options.map(option => {
                return (
                  <>
                    <option key={option.fullName} value={option.abbreviation}>
                    {option.fullName}
                    </option>
                    </>  
                )
                })}
            </Field>
            <ErrorMessage  name={name} component={ErrorInput} />
        </div>
    </>
  )
}

export default SelectInput 




