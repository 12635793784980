// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.illustrationContainer {
    width: 100%;
    display: flex; 
    gap: 15px; 
    margin: 20px auto 8px auto; 
}

@media screen and (max-width: 768px) {
    .illustrationContainer {
        flex-direction: column;  
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/illustration.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,SAAS;IACT,0BAA0B;AAC9B;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".illustrationContainer {\n    width: 100%;\n    display: flex; \n    gap: 15px; \n    margin: 20px auto 8px auto; \n}\n\n@media screen and (max-width: 768px) {\n    .illustrationContainer {\n        flex-direction: column;  \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
