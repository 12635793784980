import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; 
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext";
import ToggleContextProvider from './context/ToggleContext';
import ActiveContextProvider from './context/ActiveContext';
import EnableCountDownContextProvider from './context/EnableCountDown';
import ReferralContextProvider from './context/ReferralContext';
import { Helmet, HelmetProvider } from 'react-helmet-async'; 

import { router } from './jsx/routes';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <Provider store = {store}>
        <ThemeContext>
          <ReferralContextProvider>
            <ToggleContextProvider>
              <ActiveContextProvider>
                <EnableCountDownContextProvider>
                  <RouterProvider router={router} />
                </EnableCountDownContextProvider>
              </ActiveContextProvider>
            </ToggleContextProvider>
          </ReferralContextProvider> 
        </ThemeContext>
    </Provider>
  </HelmetProvider> 
  //  </React.StrictMode> 
);
reportWebVitals();