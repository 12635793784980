import React from 'react'
import moment from 'moment'

const CashBackVisualizer  = (props) => {
  return (
    <>
    {(props.data?.length > 0) && props.data !== null && props.data !== undefined  && <div className='transactions-wrapper-body'>
        <ul className='transactions-wrapper-body-list'>
            {props.data.map((item, index)=>{
                return (<li key={index} className='transactions-wrapper-body-list-transaction-data-item'>
                            <figure className='transaction-status-image'>
                                <img src='/images/dashboard/mycard/transaction-green.svg' alt='completed transaction' draggable='false' />  
                            </figure>
                            <div>
                                <span className='transaction-origin'>{item['type'] === 'Cashback' ? 'Cash Back' : item['type'] === 'TopupRequest' ? 'Top-up Request' : ''}</span>                                                  
                                <div className='transaction-date'><span>{`${moment(item?.date?.date).format("MMM DD, YYYY")}`}</span><span>{`${moment(item?.date?.date).format("HH:mm")}`}</span></div> 
                                <span className='transaction-value' style={{width: '127px'}}>{item['data']['amount'].toFixed(2)} CIX</span>    
                                <span className='transaction-status'>Completed</span>    
                            </div>
                        </li>)  
                })}
        </ul>
    </div>}
    {
            (props.data?.length === 0 || props.data === null || props.data === undefined ) && <div className='noRecords'>No Records</div>  
        }
    </>
  )
}

export default CashBackVisualizer 





