import React from 'react'
import './CSS/video.css' 
const Video = () => {
  return (
    <section className='VideoContainer'>
      <video id='paytapsBlackFridayVideo' autoPlay loop muted disablePictureInPicture playsInline>                                                                                                                                                                                           
        <source src="/videos/PayTaps Christmas.mp4" type="video/mp4" />   
      </video>
    </section>  
  )
}

export default Video  