import React, { useState, useEffect, useCallback } from 'react'
import './CSS/spendings.css'
import Donut from '../jsx/components/Mophy/Dashboard/Donut'                       
import { getReferralStats } from '../security/AuthService'                                                 
import Loader from '../jsx/pages/Loader/Loader' 
import { useNavigate } from 'react-router-dom'
const Spendings = () => {
  const navigate = useNavigate() 
  const [referralStats, setReferralStats] = useState({
    peopleInvited: 0, 
    maxPeopleInvited: 0,
    rewardsEarned: 0,
    maxRewardsEarnedByEvent: 0,
    referralRate: 0
  }
  )
  useEffect(()=> {
    try {
      getReferralStatistics()
    } catch(err) {

    } finally {
      setLoadingState(false) 
    }
  }, []) 

  const redirectionToAllReferredUsers = useCallback(function() {
    window.scrollTo(0,0) 
    return navigate('/referredusers') 
  }, []) 
  async function getReferralStatistics() {
    const fetch_stats = await getReferralStats()
    const result_fetch_stats = await fetch_stats.json() 
    if(result_fetch_stats['data'].length === 0) {
      return ; 
    } else {
      setReferralStats({
        ...referralStats, 
        peopleInvited: result_fetch_stats['data']['peopleInvitedEvent']['numberInvited'], 
        maxPeopleInvited: result_fetch_stats['data']['peopleInvitedEvent']['numberMaxInvitedEvent'],
        rewardsEarned: result_fetch_stats['data']['rewardEarnedEvent']['rewardEarned'],
        maxRewardsEarnedByEvent: result_fetch_stats['data']['rewardEarnedEvent']['rewardEarnedMaxEvent'],
        referralRate: result_fetch_stats['data']['referralRate']
      })
    }

  }

  const [loadingState, setLoadingState] = useState(true)  
  return (
    <>
      {!loadingState && <div className='spendingsContainer'>
        <div className='spendingsDataWrapper'>
            <div className='spendingDataItemWrapper'>
              <svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#AC39D4"/>
              </svg>
              <span className='spendingData'>
                  <span className='spendingDataLabel'>People Invited</span>
                  <span className='spendingDataValues'>
                    <span>{referralStats.peopleInvited}</span><span>/{referralStats.maxPeopleInvited}</span>
                  </span>
              </span>
            </div> 
            <div className='spendingDataItemWrapper'>
            <svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#40D4A8"/>
            </svg>
              <span className='spendingData'>
                  <span className='spendingDataLabel'>Referral Rate</span>
                  <span className='spendingDataValues'>
                    <span>{referralStats.referralRate.toFixed(1)}%</span><span></span>
                  </span>
              </span>
            </div>
            <div className='spendingDataItemWrapper'>
            <svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#461EE7"/>
            </svg>
              <span className='spendingData'>                                                                                                             
                  <span className='spendingDataLabel'>Rewards Earned</span>
                  <span className='spendingDataValues'>
                    <span>${referralStats.rewardsEarned.toFixed(1)}</span><span>/${referralStats.maxRewardsEarnedByEvent}</span>  
                  </span>
              </span>
            </div>
        </div>

        <div className='illustrationsWrapper'> 
          <div className="bg-secondary rounded text-center p-3">
            <div className="d-inline-block position-relative donut-chart-sale">
              <Donut value={referralStats.maxPeopleInvited > 0 ? ((100 * referralStats.peopleInvited) / referralStats.maxPeopleInvited) : 0} backgroundColor="rgb(255, 255, 255)" backgroundColor2="rgba(0,0,0,0.1)"/>
              <span className="text-white illustrationValue">{referralStats.peopleInvited}</span>
            </div>
            <span className="dataTextualLabel text-white d-block">People Invited</span>
          </div>
          <div className="bg-success rounded text-center p-3">
            <div className="d-inline-block position-relative donut-chart-sale">
              <Donut value={referralStats.referralRate} backgroundColor="rgb(255, 255, 255)" backgroundColor2="rgba(0,0,0,0.1)"/>
              <span className="text-white illustrationValue">{referralStats.referralRate.toFixed(1)}%</span>
            </div>
            <span className="dataTextualLabel text-white d-block">Referral Rate</span> 
          </div>
          <div className="bg-info rounded text-center p-3">
            <div className="d-inline-block position-relative donut-chart-sale">
              <Donut value={referralStats.maxRewardsEarnedByEvent > 0 ? ((100 * referralStats.rewardsEarned) / referralStats.maxRewardsEarnedByEvent) : 0} backgroundColor="rgb(255, 255, 255)" backgroundColor2="rgba(0,0,0,0.1)"/>
              <span className="text-white illustrationValue">{referralStats.rewardsEarned.toFixed(1)}$</span>  
            </div>
            <span className="dataTextualLabel text-white d-block">Rewards Earned</span>
          </div>
          <div style={{cursor: 'pointer'}} className="border border-2 border-primary rounded text-center p-3" onClick={redirectionToAllReferredUsers}>
            <div className="d-inline-block position-relative donut-chart-sale">
              <img src='/images/dashboard/referral/ph_user-list-bold.svg' alt='icon of person constructed of a circle below it an arc and in the right of it 3 recatngles with rounded corners, and the last of them has a less width than others' />  
            </div>
            <span className="dataTextualLabel text-1E96EB d-block">See All Referred Users</span>   
          </div> 
        </div>
													
      </div>
      }
      {loadingState && <div className='waitLoadingProfileData h-50vh'><div>Please wait...</div><Loader /></div>}     
    </>
  )
}

export default Spendings
