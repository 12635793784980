import React, { useState, useRef, useCallback, useEffect } from 'react'
import './CSS/dashboard.css'
import Button from './../components/Button'
import WalletDeposit from '../Modals/WalletDeposit'
import { useLoaderData, useNavigate } from 'react-router-dom' 
import UnsufficientBalance from '../Modals/UnsufficientBalance';
import { useStateCountDown } from '../context/EnableCountDown'   
import { getAllDeposits, userBalance, cardInfo, refreshToken, killToken, saveToken, getCardBalance, convertToUSDT } from '../security/AuthService'    
import Loader from '../jsx/pages/Loader/Loader' 
import { useActive } from '../context/ActiveContext'  
import Topup from '../Modals/Topup' 
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up'  
import TopupSuccess from '../Modals/TopupSuccess' 
import { useReferral } from '../context/ReferralContext'
import OrderCardNow from '../components/OrderCardNow'  
import Illustration from '../components/Illustration' 
import Banner from '../components/Banner' 

const Dashboard = () => {
  const { showReferral, handleShowReferral } = useReferral() 
  const {stateCountDown, setStateCountDown} = useStateCountDown()
  const [show, setShow] = useState(false)
  const result = useLoaderData() 
  const [unsufficientBalanceState, setUnsufficientBalanceState] = useState(false)
  const refAllow = useRef(false) 
  const [clickDepositStatus, setClickDepositStatus] = useState(false) 
  const navigate = useNavigate() 

  const [balanceUSDT, setBalanceUSDT] = useState('') 
  const [dashboardataLoadingState, setDashboardataLoadingState] = useState(true) 
  const [depositTrx, setdepositTrx] = useState({data: []})
  const [filtereddepositTrx, setFiltereddepositTrx] = useState({data: []}) 
  const [creditLimit, setCreditLimit] = useState('0')    
  const { handleMenuActive } = useActive() 
  const [cardBalance, setCardBalance] = useState('0')
  const [showTopUp, setShowTopUp] = useState(false) 
  const [hasCard, setHasCard] = useState(false) 
  const [balanceEUR, setBalanceEUR] = useState(0)  
  const [showSuccess, setShowSuccess] = useState(false)                                                                                  
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  
    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);
  useEffect(()=>{
    HandleAllDeposits()
    if(typeof result === 'string') {
      refAllow.current = false
    } else if((typeof result === 'object')) {
      refAllow.current = false 
    }
  }, []) 
    
    const handleClose = () => {setShow(false); setStateCountDown(false)} 

    const handleShow = function(){
      if(typeof result === 'string') {
        navigate('/thankyoufordeposit')  
        return handleMenuActive('')        
      }
      if(result.normal_deposit) {
        setShow(true); 
        setStateCountDown(true)
      } else if(!result.normal_deposit) {
        handleShowUnsBalance() 
        refAllow.current = true 
      }
      setClickDepositStatus(true) 
    }
     
    function setRefFalse() {
      setStateCountDown(false)
    }
    function setRefTrue() { 
      setStateCountDown(true)
    }
    
    const handleShowUnsBalance = useCallback(function() {
      setUnsufficientBalanceState(true)
    }, [])
    const handleCloseUnsBalance = useCallback(function() {
      setUnsufficientBalanceState(false)
    }, [])
    const handleclickDepositStatusOff = useCallback(function() {
      setClickDepositStatus(false) 
    }, []) 
    const updateBalance = useCallback(function (newBalance) {
      setBalanceUSDT(newBalance) 
  }, [])   

    async function HandleAllDeposits() {
      
      try {
          setDashboardataLoadingState(true) 
            let result_fetch_balance_wallet = await userBalance() 

            // If fetch returns 401 
              if(result_fetch_balance_wallet.status === 401) {
                const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                // If refresh token returns 401
                if(refreshTokenResult.status === 401) {
                  killToken()
                  return navigate('/login')
                } 

                // If refresh token is successfull 
                const payloadRefreshToken = await refreshTokenResult.json()   

                let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                // Payload of new generated token 
                let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                  killToken()
                  return navigate('/login') 
                } else {
                  saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
                  result_fetch_balance_wallet = await userBalance()   
                }

              }


            const wallet_balance_usdt = await result_fetch_balance_wallet.json()
            setBalanceUSDT(wallet_balance_usdt['data'][0]['balance']) 
            const convert = await convertToUSDT({
              amount: wallet_balance_usdt['data'][0]['balance'] 
            })  

            const convert_result = await convert.json()
            let resultfetchAllDeposits = await  getAllDeposits()
            // If fetch returns 401 
            if(resultfetchAllDeposits.status === 401) {
              const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              // If refresh token returns 401
              if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
              } 

              // If refresh token is successfull 
              const payloadRefreshToken = await refreshTokenResult.json()   

              let tokenPayload = payloadRefreshToken.token.split('.')[1] 

              // Payload of new generated token 
              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return navigate('/login') 
              } else {
                saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
                resultfetchAllDeposits = await  getAllDeposits()   
              }

            }

            const res = await resultfetchAllDeposits.json() 


            let card_fetch_info = await cardInfo()
            // If fetch returns 401 
            if(card_fetch_info.status === 401) {
              const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              // If refresh token returns 401
              if(refreshTokenResult.status === 401) {
                killToken()
                return navigate('/login')
              } 

              // If refresh token is successfull 
              const payloadRefreshToken = await refreshTokenResult.json()   

              let tokenPayload = payloadRefreshToken.token.split('.')[1] 

              // Payload of new generated token 
              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return navigate('/login') 
              } else {
                saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
                card_fetch_info = await cardInfo()     
              }

            }

            const result_card_info = await card_fetch_info.json()


            if(result_fetch_balance_wallet.ok && resultfetchAllDeposits.ok && card_fetch_info.ok && convert.ok) {  
              setdepositTrx({...depositTrx, data: res['data'].reverse()})
              setFiltereddepositTrx({...filtereddepositTrx, data: res['data']})   
              const balance_eur = wallet_balance_usdt['data'][0]['balance'] / convert_result['data']['info']['rate'] 
              setBalanceEUR(balance_eur)
              if(result_card_info['data'].length > 0) {
                if(!showReferral) {
                  handleShowReferral() 
                }
                const card_balance = await getCardBalance() 
                if(card_balance.ok) {                  
                  const card_balance_result = await card_balance.json()
                  setCardBalance(card_balance_result['data']['balance'])
                }
                setCreditLimit(result_card_info['data'][0]['creditLimit'])  
                setHasCard(true)   
              } else {
                setCreditLimit('0') 
                setHasCard(false)  
              }
                                        
              setDashboardataLoadingState(false) 
 
            } 
      } catch(err) {
      }
    }  
    
const handleCloseTopUp = useCallback(function() {
  setShowTopUp(false) 
}, [])
const topupCardHandler = useCallback(function (e) {
  e.stopPropagation()
  if(result?.normal_deposit) {
    setShowTopUp(true) 
  }
}) 
const handleCloseTopupSuccess = useCallback(function () {
  setShowSuccess(false) 
}, [])
const handleShowTopupSuccess = useCallback(function () {
  setShowSuccess(true)
}, [])
const orderCardHandler = useCallback(function (e) {
  e.stopPropagation() 
  handleMenuActive('') 
  navigate('/ordercard')
}, []) 
const redirectToCardHandler = useCallback(function () {
  if(result?.normal_deposit && hasCard) {
    handleMenuActive('My Card') 
  } else {
    handleMenuActive('')
  }
  navigate('/mycard') 
})
      
return (
    <>
    {!dashboardataLoadingState && <div className='dashboard-container'>
        <section className='dashboard-overview-container'> 

            <figure className='available-balance paytapsCard'>
            <span className='mainWalletTitle'>Main wallet</span>
              <span className='available-balance-euro'>Available balance in USDT</span>
                <span className='available-balance-euro-amount'>{parseFloat(balanceUSDT).toFixed(2)}</span>      
                <Button imagesrc='/images/dashboard/dashboard/Background (2).svg' styles='deposit-btn' click={handleShow}>Deposit</Button>
               

            </figure>  

            <figure className='paytapsCardBgWrapper' onClick={redirectToCardHandler}> 
              <img src='/images/dashboard/mycard/paytaps card.png' /> 
              <span className='available-balance-euro'>Available balance in EUR</span>
                <span className='available-balance-euro-amount'>{parseFloat(cardBalance).toFixed(2)}</span> 
                {(result?.normal_deposit && hasCard) ? <Button imagesrc='/images/dashboard/dashboard/Background (3).svg' styles='topup-btn' click={topupCardHandler}>Top up</Button> :  <Button imagesrc='/images/dashboard/dashboard/Icon.svg' styles='topup-btn w-147 h-35' click={orderCardHandler}>Order Card</Button>}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
            </figure>
            
            <div className="card bgl-primary card-body overflow-hidden p-0 d-none d-md-flex rounded " style={{height: '100%', marginBottom: '0px'}}>
							<div className="p-0 text-center mt-3" style={{zIndex: '2'}}> 
									<span className="text-black">Limit</span>
									<h3 className="text-black fs-20-m18 mb-0 font-w600">€{parseFloat(creditLimit).toFixed(1)}</h3>                         
									<small>€{localStorage.getItem('c_l') === '100000' ? '100K' : localStorage.getItem('c_l')}</small>  
							</div>
							<div className="mt-auto line-chart-demo" style={{position: 'absolute',background: '#1eaae780', inset: '0', height: `${parseFloat(localStorage.getItem('c_l')) > 0 ? ((parseFloat(creditLimit) * 100) /parseFloat(localStorage.getItem('c_l'))): 0}%`}}>                                                                                                           

							</div>									
						</div>
            

        </section>
        <Banner /> 
        {!result.has_card && <OrderCardNow />}
        {result.has_card && <Illustration />}    
    </div>}
    {(dashboardataLoadingState) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}

{(result?.normal_deposit && clickDepositStatus) && <WalletDeposit show={show} handleClose={handleClose} stateCountDown={stateCountDown} setRefFalse={setRefFalse} setRefTrue={setRefTrue} handleclickDepositStatusOff={handleclickDepositStatusOff} /> }   
{(!result?.normal_deposit && clickDepositStatus) && <UnsufficientBalance show={unsufficientBalanceState} handleClose={handleCloseUnsBalance} a={refAllow.current} handleclickDepositStatusOff={handleclickDepositStatusOff} />    }     
  <Topup show={showTopUp} handleClose={handleCloseTopUp} handleShowInsufTopupBalance={handleShowInsufTopupBalance} updateBalance={updateBalance}  balanceEUR={balanceEUR} balanceUSDT={balanceUSDT} handleShowTopupSuccess={handleShowTopupSuccess} />                                            
  <InsufficientBalance_top_up show={showInsufficientTopupBalance} handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} handleShowInsufTopupBalance={handleShowInsufTopupBalance} />                                                                         
  <TopupSuccess show={showSuccess} handleClose={handleCloseTopupSuccess} />       
</>
  )
}

export default Dashboard

