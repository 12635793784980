import React from 'react'
import './CSS/resetpasswordemail.css' 
import { Link,useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import TextInput from '../components/TextInput'
import { generateOTPForgetPassword, refreshToken, killToken, saveToken  } from '../security/AuthService'                                  
import SEO from '../components/SEO'
import SnowFallParent from '../components/SnowFallParent'                                                                                                                                                                                                                                                
const ResetPasswortEmail = () => {
    const navigate = useNavigate()
    
  return (
    <>
      <SEO title={'PayTaps - Your Crypto Payment Solution'} 
          description={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          ogTitle={'PayTaps Crypto Mastercard – Spend Crypto With A Simple Tap'} 
          ogDescription={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          ogUrl={'https://paytaps.com'}  
          twitterTitle={'PayTaps Crypto Mastercard – Spend Crypto With A Simple Tap'} 
          twitterDescription={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          canonicalLink={'https://paytaps.com'}    
      /> 
      <section className='resetPasswordEmailContainer'>
      <SnowFallParent snowflakecount={50} radius={[2.5, 10]} />              
        <div className='resetPasswordEmailContent'>
            <Link to='/'>
                <img src='/images/registration/Group 1.svg' alt='paytabs logo' draggable='false' className='paytapsLogo' />
            </Link>
            <div className='resetPassword'>Reset Password </div>
            <div className='resetPasswordEnterCodeSentEmail'>Enter your Email an OTP code will be sent to you!</div>
            <Formik
                            initialValues={{
                                email: ''
                            }}

                            validationSchema={YUP.object({
                                email: YUP.string().required('Required!').email('Invalid Email!'),
                            })}

                            onSubmit={ async (values, {setSubmitting, resetForm})=>{
                              try {

                              
                                // console.log("reponse") 
                                let result = await generateOTPForgetPassword({email: values.email}) 
                                // If fetch returns 401 
                                if(result.status === 401) {
                                  const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

                                  // If refresh token returns 401
                                  if(refreshTokenResult.status === 401) {
                                  killToken()
                                  return navigate('/login')
                                  } 

                                  // If refresh token is successfull 
                                  const payloadRefreshToken = await refreshTokenResult.json()   

                                  let tokenPayload = payloadRefreshToken.token.split('.')[1] 

                                  // Payload of new generated token 
                                  let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

                                  if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                                      killToken()
                                      return navigate('/login') 
                                  } else {
                                      saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
                                      result = await generateOTPForgetPassword({email: values.email})    
                                  }

                                } 

                                // console.log("reponse2",result) 
                                const reponse = await result.json()
                                // console.log("reponse3",reponse) 
                                if(result.ok && reponse.message === 'successfully') {
                                  // console.log(reponse) 
                                  navigate('/resetpasswordverification', { state: { email: values.email } })
                                }
                                setSubmitting(false)
                                resetForm()
                              } catch(err) {

                              }
                            }}
                        >
                            
                                {(formik) => (
                                  <Form className='form' onSubmit={formik.handleSubmit}>
                                    <TextInput
                                      inputtype='email'
                                      name='email'
                                      id='email'
                                      label='Email'
                                      // error={formik.errors.email}
                                      // touched={formik.touched.email}
                                      // onChange={formik.handleChange}  
                                      // onBlur={formik.handleBlur}      
                                    />
                                    <button
                                      type='submit'
                                      id='resetpassword' 
                                      disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
                                    >
                                      Reset
                                    </button>
                                  </Form>
              )}      
            </Formik>
                        

                        <div className='already-have-account'>
                            Remember It? 
                            <Link to='/login' style={{
                                fontWeight: '700',
                                color: '#0E0E0E'
                            }}>  Sign in here </Link>
                        </div>
        </div>
      </section>
    </>
  )
}

export default ResetPasswortEmail 