export const MenuList = [
    {
        title: 'Dashboard',
        to: '/dashboard',
        active_icon: '/images/dashboard/sidebar/dashboard_a.svg',
        normal_icon: '/images/dashboard/sidebar/dashboard_na.svg',
        id: 'dashboard'					
    },
    {
        title: 'Deposit Fund',
        to: '/depositfund',
        active_icon: '/images/dashboard/sidebar/depositfund_a.svg',
        normal_icon: '/images/dashboard/sidebar/depositfund_na.svg', 
        id:'depositFund'					
    },
    {
        title: 'My Card',
        to: '/mycard',	
        active_icon: '/images/dashboard/sidebar/card_a.svg',
        normal_icon: '/images/dashboard/sidebar/card_na.svg',
        id: 'card'				
    }, 
    {
        title: 'Top-up Card', 
        to: '/topupcard',	
        active_icon: '/images/dashboard/sidebar/topup_a.svg',
        normal_icon: '/images/dashboard/sidebar/topup_na.svg',
        id: 'topupCard' 				
    },
    {
        title: 'Transactions History',
        to: '/transactionshistory',
        active_icon: '/images/dashboard/sidebar/transactionshistory_a.svg',
        normal_icon: '/images/dashboard/sidebar/transactionshistory_na.svg',
        id:'transactions_history'					
    },
    {
        title: 'Profile',
        to: '/profile',
        active_icon: '/images/dashboard/sidebar/tabler-user-circle_a.svg',
        normal_icon: '/images/dashboard/sidebar/tabler-user-circle_na.svg',
        id:'profile'					
    },
    {
        title: 'Customer Support',
        to: '/contactus',
        active_icon: '/images/dashboard/sidebar/customersupport_a.svg',
        normal_icon: '/images/dashboard/sidebar/customersupport_na.svg',
        id:'contactUs'					
    },
    {
        title: 'User Guide',
        to: '/userguide',
        active_icon: '/images/dashboard/sidebar/userguide_a.svg',
        normal_icon: '/images/dashboard/sidebar/userguide_na.svg',
        id:'user_guide'					
    },
    {
        title: 'Referral',
        to: '/welcomereferral',
        active_icon: '/images/dashboard/sidebar/referral_a.svg',
        normal_icon: '/images/dashboard/sidebar/referral_na.svg',
        id:'welcomeReferral' 					
    },
]