import React from 'react'
import moment from 'moment'   

const DepositVisualizer = (props) => {
  return (
    <>
    {(props.data?.length > 0) && props.data !== null && props.data !== undefined  && <div className='transactions-wrapper-body'>
        <ul className='transactions-wrapper-body-list'>
            {props.data.map((item, index)=>{
                return (<li key={index} className='transactions-wrapper-body-list-transaction-data-item'>
                            <figure className='transaction-status-image'>
                                {   item.status === "Success" ? 
                                    <img src='/images/dashboard/mycard/transaction-green.svg' alt='completed transaction' />  :
                                    (item.status === 'Decline' ? 
                                    (<img src='/images/dashboard/mycard/transaction-red.svg' alt='declined transaction' /> ) :
                                    (
                                        (<img src='/images/dashboard/dashboard/SVG (4).svg' alt='pending transaction' />)) )       
                                }
                            </figure>
                            <div>
                                <span className='transaction-origin'>{item['type'] === 'referrer' ? 'Referral Reward' : item['type'] === 'singupBonus' ? 'Sign-Up Bonus' : 'Deposit Request'}</span>                                              
                                <div className='transaction-date'><span>{`${moment(item?.createdAt?.date).format("MMM DD, YYYY")}`}</span><span>{`${moment(item?.createdAt?.date).format("HH:mm")}`}</span></div> 
                                <span className='transaction-value'>${item['amount']}</span> 
                                <span className={`transaction-status ${item.status !== 'Success' ? (item.status === 'Decline' ? 'declinedColor' : 'pendingColor') : ''}`}>{item.status === 'Decline' ? 'Declined' : item.status}</span>   
                            </div>
                        </li>)  
                })}
        </ul>
    </div>}
    {
            (props.data?.length === 0 || props.data === null || props.data === undefined ) && <div className='noRecords'>No Records</div>  
        }
    </>
  )
}

export default DepositVisualizer  