import React from 'react'
import './checkemail.css' 
const CheckEmail = () => {
  return (
    <>
        <div className='verifyAccountCheckEmailContainer'>
            <div className='verifyAccountCheckEmailInnerContainer'>
                <div className='verifyAccountCheckEmailContentWrapper'>
                    <img src='/images/registration/logo.png' 
                         alt='An open gray envelope, in front of which is a paper rocket containing two cards, and on the top right of the envelope is a blue shield containing the Paytabs logo. Under the envelope and the paper rocket is a black line (representing the ground) and a plant with blue leaves'
                         className='d-block m-auto imgWidth'  
                    />  
                    <div className='checkEmailTitle'>Check your email</div>
                    <div className='checkEmailDescription'>We sent a verification link to your email</div>
                    <button id='sendVerificationEmail'>Send again</button>
                </div>
            </div>
        </div> 
    </>
  )
}

export default CheckEmail  