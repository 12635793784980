import React, { useEffect } from 'react'
import './CSS/confirmtermsconditions.css' 
import { Modal } from 'react-bootstrap'
import Button from '../components/Button' 
const ConfirmTermsConditions = ({show, handleShowTermsConditions, handleCloseTermsConditions, confirm_TC, confirm_TC_false, handleCloseTermsTC}) => {
    
    useEffect(()=>{
      
      if(document.getElementById('confirmTermsConditionsBtn')) { 
        document.getElementById('confirmTermsConditionsBtn').addEventListener('click', (e)=>{
          e.preventDefault();  
          handleCloseTermsConditions() 
          confirm_TC() 
        })
      }
    })
    useEffect(()=>{ 
        
      if(document.getElementById('bodyTC')) {
        document.getElementById('bodyTC').addEventListener('scroll', function(){
            
          if(document.querySelector('.endPoint').getBoundingClientRect().top <= 559) {
            document.getElementById('confirmTermsConditionsBtn').disabled = false 
          }  else {
            document.getElementById('confirmTermsConditionsBtn').disabled = true    
          }                                     
        })
      } 
    }) 


  return (
    <Modal show={show} onHide={handleCloseTermsConditions} className='confirmTermsConditions' backdrop='static' keyboard='false'>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body id='bodyTC'>
                    <p>These Cardholder Terms and Conditions ("Terms") govern the issuance and use of the Virtual Card ("Card") provided by Paytaps ("Issuer"). By applying for and using the Card, you agree to comply with these Terms.</p>
                    <h5>1. Card Issuance:</h5>
                    <p>1.1. The Card is a virtual debit card issued by the Issuer.</p>
                    <p>1.2. The Card is available to individuals who meet the Issuer's eligibility criteria.</p>
                    <p>1.3. The Card is not transferable, and its use is restricted to the authorized cardholder.</p>
 
                    <h5>2. Card Activation:</h5>
                    <p>2.1. Upon issuance, the Cardholder must activate the Card by following the provided instructions.</p>
                    <p>2.2. The Cardholder is responsible for keeping the activation details confidential.</p>
 
                    <h5>3. Card Use:</h5>
                    <p>3.1. The Card may be used for online transactions and where Visa cards are accepted.</p>
                    <p>3.2. The Card may not be used for any illegal, fraudulent, or prohibited activities.</p>
                    <p>3.3. The Cardholder is responsible for all transactions conducted with the Card.</p>
 
                    <h5>4. Authorization Failure Rate Monitoring:</h5>
                    <p>4.1. The Issuer monitors authorization failure rates.</p>
                    <p>4.2. The Authorization Failure Rate Categories and Associated Penalties may be subject to change based on certain factors or circumstances.</p>
 
                    <h5>5. Transaction Rejections:</h5>
                    <p>5.1. If a single Card has more than 5 consecutive rejections, the Issuer reserves the right to deactivate the Card.</p>
                    <p>5.2. The Cardholder should contact the customer platform to unbind the Card.</p>
 
                    <h5>6. Risk Blacklist Platforms:</h5>
                    <p>6.1. The Issuer maintains a list of blacklisted platforms.</p>
                    <p>6.2. Transactions with platforms on the blacklist may be declined.</p>
 
                    <h5>7. Dispute Resolution:</h5>
                    <p>7.1. In case of any disputes or discrepancies regarding Card transactions, the Cardholder should promptly notify the Issuer.</p>
                    <p>7.2. The Issuer will investigate the dispute and take appropriate action within a reasonable timeframe.</p>
 
                    <h5>8. Card Replacement:</h5>
                    <p>8.1. In the event of a lost or stolen Card, the Cardholder should promptly report it to the Issuer.</p>
                    <p>8.2. A fee may be applicable for card replacement.</p>
 
                    <h5>9. Card Expiry:</h5>
                    <p>9.1. The Card has an expiry date. It is the Cardholder's responsibility to ensure the Card is renewed before expiration.</p>
 
                    <h5>10. Card Security:</h5>
                    <p>10.1. The Cardholder is responsible for maintaining the security of the Card details, including the Card number, and CVV.</p>
                    <p>10.2. The Cardholder should not share these details with anyone and should store them securely.</p>
 
                    <h5>11. Card Limits:</h5>
                    <p>11.1. The Issuer may impose daily, monthly, or transactional limits on the Card.</p>
                    <p>11.2. These limits are subject to change at the Issuer's discretion.</p>
 
                    <h5>12. Privacy and Data Protection:</h5>
                    <p>12.1. The Issuer collects and processes personal information as described in the Privacy Policy.</p>
                    <p>12.2. The Cardholder's information is kept confidential, and its use is in compliance with applicable data protection laws.</p>
 
                    <h5>13. Account Closure:</h5>
                    <p>13.1. The Cardholder may request the closure of their account at any time by contacting the Issuer.</p>
                    <p>13.2. Uponaccount closure, any remaining card balance will be processed as per the Issuer's policies.</p>                
                    <h5>14. Cardholder Responsibilities:</h5>
                    <p>14.1. The Cardholder is responsible for all actions taken with the Card.</p>
                    <p>14.2. The Cardholder should report any suspicious or unauthorized transactions to the Issuer.</p>
 
                    <h5>15. Communication:</h5>
                    <p>15.1. The Cardholder agrees to receive communications, statements, and notices from the Issuer electronically or via other methods.</p>
 
                    <h5>16. Severability:</h5>
                    <p>16.1. If any clause or provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>
 
                    <h5>17. Entire Agreement:</h5>
                    <p>17.1. These Terms constitute the entire agreement between the Cardholder and the Issuer and supersede any prior agreements.</p>
 
                    <h5>18. Amendments by Cardholder:</h5>
                    <p>18.1. Cardholders may request amendments or changes to their Card information by contacting the Issuer.</p>
 
                    <h5>19. Cardholder Acknowledgment:</h5>
                    <p>19.1. By using the Card, the Cardholder acknowledges that they have read, understood, and agreed to these Terms.</p>
 
                    <h5>20. Indemnification:</h5>
                    <p>20.1. The Cardholder agrees to indemnify and hold the Issuer harmless from any claims, losses, or damages arising from the Cardholder's use of the Card.</p>
 
                    <h5>21. Governing Law:</h5>
                    <p>21.1. These Terms are governed by the laws of “Lithuania“.</p> 
 
                    <h5>22. Amendments:</h5>
                    <p>22.1. The Issuer may amend these Terms at its discretion. Cardholders will be notified of any changes.</p>
 
                    <h5>23. Contact Information:</h5>
                    <p>23.1. For any questions or concerns, please contact <span className='endPoint'>customercare@paytaps.com.</span></p>  
        </Modal.Body>
        <Modal.Footer id='footerF'>
        <Button id='confirmTermsConditionsBtn' disabled>
            Confirm
          </Button>
          <Button click={()=> {handleCloseTermsConditions()}} id='cancelTermsConditions'> 
            Cancel
          </Button>
        </Modal.Footer>
    </Modal>    
  )
}

export default ConfirmTermsConditions 
