import React, { useState, useEffect, useCallback } from 'react'
import './CSS/referreduserslist.css'  
import ReferralChunkData from '../components/ReferralChunkData' 
import InviteesList from '../components/InviteesList'
import Loader from '../jsx/pages/Loader/Loader'
import { getAllReferralRewards, getAllInvitedPeople, getGeneralReferralRate } from '../security/AuthService' 
import { useNavigate } from 'react-router-dom'    
import { useActive } from '../context/ActiveContext'
import { useReferral } from '../context/ReferralContext'                                                                
const ReferredUsersList = () => {
  const { showReferral, handleShowReferral } = useReferral() 
  const { handleMenuActive } = useActive() 
  const navigate = useNavigate() 
  const [loadingState, setLoadingState] = useState(true) 
  const [referralGlobalStats, setReferralGlobalStats] = useState(
    {
      invitedPeople: 0,
      rewardsEarned: 0,
      referralRate: 0
    } 
  )
  useEffect(()=>{
    try {
      getReferralGlobalStats() 
    } catch(err) {

    } finally {
      setLoadingState(false)  
    } 
  }, []) 
  const getReferralGlobalStats = useCallback(async function() {
    if(!showReferral) {
      handleShowReferral() 
    }
    const fetch_all_referral_rewards = getAllReferralRewards()
    const fetch_all_invited_people = getAllInvitedPeople()
    const fetch_general_referral_rate = getGeneralReferralRate() 
    let responseStatus_fetch_all_referral_rewards
    let responseStatus_fetch_all_invited_people
    let responseStatus_fetch_general_referral_rate
    const [rewardsResult, invititedPeopleResult, referralRateResult] = await Promise.all(
      [
        fetch_all_referral_rewards.then(res=> {responseStatus_fetch_all_referral_rewards = res.status; return res.json()}), 
        fetch_all_invited_people.then((res)=> {responseStatus_fetch_all_invited_people = res.status; return res.json()}), 
        fetch_general_referral_rate.then((res)=> {responseStatus_fetch_general_referral_rate = res.status; return res.json()}) 
      ])
          
      if((responseStatus_fetch_all_invited_people === 200 && invititedPeopleResult['message'] === 'Success')) {
        if(invititedPeopleResult['data'].length > 0) {
          setReferralGlobalStats((referralGlobalStats)=> ({
            ...referralGlobalStats,
            invitedPeople: invititedPeopleResult['data'][0]['peopleInvited']
          }) ) 
        }
      }


      if((responseStatus_fetch_all_referral_rewards === 200 && rewardsResult['message'] === 'Success')) {
        if(rewardsResult['data'].length > 0) {
          setReferralGlobalStats((referralGlobalStats)=> ({
            ...referralGlobalStats, 
            rewardsEarned: rewardsResult['data'][0]['Rewards'] 
          }))
        
        }
      } 

       

      if((responseStatus_fetch_general_referral_rate === 200 && referralRateResult['message'] === 'Success')) {
        if(referralRateResult['data'].length > 0) {
          setReferralGlobalStats((referralGlobalStats)=> ({
            ...referralGlobalStats, 
            referralRate: referralRateResult['data'][0]['referralRate'] 
          }))   
        }
      }             

  })
  const returnBackHandler = useCallback(function () {
    handleMenuActive('Referral') 
    return navigate('/welcomereferral')
  }, [])  
  return (
    <>
        {!loadingState && <div className='referredUsersListContainer'>
            <div className='backFromReferredUsersList'>
                <button  id='backFromReferredUsers' type='button' onClick={returnBackHandler}><img src='/images/dashboard/referral/ic_round-arrow-back.svg' alt='a black arrow oriented to the left' /></button><span className='referredUsersList'>Referred Users List</span> 
            </div>

            <div className='referralChunkDataWrapper'>
                <ReferralChunkData imagesrc='/images/dashboard/referral/Group 466 (1).svg' 
                alt='a blue circle in the center of it 2 icons of persons with left one hidding the left part of the second person icon' 
                title='People Invited'
                description='You’ve shared your referral link with'
                value={referralGlobalStats.invitedPeople} />   
                <ReferralChunkData imagesrc='/images/dashboard/referral/Group 466 (2).svg' 
                alt='a blue circle in the center of it a badge'
                title='Rewards Earned'
                description='You’ve earned'
                value={`$${referralGlobalStats.rewardsEarned.toFixed(1)}`} /> 
                <ReferralChunkData imagesrc='/images/dashboard/referral/Group 466 (3).svg' 
                alt='a blue circle in the center of it a division symbol'
                title='Referral Rate'
                description='Your referral success rate is'
                value={`${referralGlobalStats.referralRate.toFixed(1)}%`} />  

            </div>

            <InviteesList /> 
        </div>} 
        {loadingState && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}                       
    </>
  )
}

export default ReferredUsersList                                                                                                                                                                                                                                                                 