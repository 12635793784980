// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referralChunkDataContainer {
    background: #FFFFFF;
    padding: 16px 16px 27px 16px; 
    border-radius: 9.32px;
    box-shadow: 0 4.66px 4.66px 0 #523F690D; 
} 
.referralChunkDataFirstRow {
    display: flex;
    gap: 8px;
    align-items: center; 
}
.referralChunkDatatextualDescription {
    display: flex;
    flex-direction: column;
}
.referralChunkDatatextualDescription > span:first-of-type {
    font-family: var(--font-poppins), sans-serif;  
    font-size: 18.64px;
    font-weight: 500;
    line-height: 27.96px;
    color: #000000;
}
.referralChunkDatatextualDescription > span:nth-of-type(2) {
    font-family: var(--font-poppins), sans-serif;  
    font-size: 12px;
    font-weight: 400;
    line-height: 16.96px;
    color: #7E7E7E; 
}
.referralChunkDataSecondRow {
    font-family: var(--font-poppins), sans-serif;  
    padding-left: 8px;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    color: #000000;
    margin-top: 13px; 
    word-wrap: break-word; 
}

@media screen and (max-width: 700px) {
    .referralChunkDataSecondRow {
        font-size: 40px;  
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/referralchunkdata.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,qBAAqB;IACrB,uCAAuC;AAC3C;AACA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,4CAA4C;IAC5C,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;AAClB;AACA;IACI,4CAA4C;IAC5C,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;AAClB;AACA;IACI,4CAA4C;IAC5C,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,cAAc;IACd,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".referralChunkDataContainer {\n    background: #FFFFFF;\n    padding: 16px 16px 27px 16px; \n    border-radius: 9.32px;\n    box-shadow: 0 4.66px 4.66px 0 #523F690D; \n} \n.referralChunkDataFirstRow {\n    display: flex;\n    gap: 8px;\n    align-items: center; \n}\n.referralChunkDatatextualDescription {\n    display: flex;\n    flex-direction: column;\n}\n.referralChunkDatatextualDescription > span:first-of-type {\n    font-family: var(--font-poppins), sans-serif;  \n    font-size: 18.64px;\n    font-weight: 500;\n    line-height: 27.96px;\n    color: #000000;\n}\n.referralChunkDatatextualDescription > span:nth-of-type(2) {\n    font-family: var(--font-poppins), sans-serif;  \n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16.96px;\n    color: #7E7E7E; \n}\n.referralChunkDataSecondRow {\n    font-family: var(--font-poppins), sans-serif;  \n    padding-left: 8px;\n    font-size: 48px;\n    font-weight: 600;\n    line-height: 1;\n    color: #000000;\n    margin-top: 13px; \n    word-wrap: break-word; \n}\n\n@media screen and (max-width: 700px) {\n    .referralChunkDataSecondRow {\n        font-size: 40px;  \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
