import React, { lazy } from 'react'

/// React router dom
import {Route, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom'
import { userBalance, findRequestCard, getAllDeposits, killToken, refreshToken, saveToken, getReferralCode } from '../security/AuthService'               


/// Css
import './index.css'
import './chart.css'
import './step.css'



import MainLayout from '../jsx/index'

import Lplayout from '../landingpage/Lplayout'

import LpBody from '../landingpage/LPBody/LpBody'

import Registration from '../Registration/Register'


import Login from '../login/Login'

import MyCard from '../dashboard/MyCard'
import Dashboard from '../dashboard/Dashboard'
import MyInfo from '../dashboard/MyInfo'
import OrderCard from '../dashboard/OrderCard'
import ThankYouOrderCard from '../dashboard/ThankYouOrderCard' 
import ThankYouForDeposit from '../dashboard/ThankYouForDeposit'
import ErrorPage from '../dashboard/ErrorPage' 
import Contact from '../dashboard/Contact' 
import DepositFund from '../dashboard/DepositFund'  
import ResetPasswortEmail from '../resetpassword/ResetPasswortEmail'
import ResetPasswordNewPassword from '../resetpassword/ResetPasswordNewPassword' 
import ResetPasswordOTP from '../resetpassword/ResetPasswordOTP'   
import ResetPasswordPage from '../inresetpassword/ResetPasswordPage'  
import ResetPasswordSuccess from '../inresetpassword/ResetPasswordSuccess' 
import TopUpCard from '../dashboard/TopUpCard'   
import Instruction from '../dashboard/Instructions'  
import TransactionsHistory from '../dashboard/TransactionsHistory' 
import WelcomeReferral from '../dashboard/WelcomeReferral' 
import ReferredUsersList from '../dashboard/ReferredUsersList' 
import GetStarted from '../dashboard/GetStarted'   
import CheckEmail from '../verification/CheckEmail' 
const ForgotPassword = lazy(() => import('../jsx/pages/ForgotPassword'));

const allroutes = [
    /// Dashboard
    // { url: '', component: <Home/> },
    { url: 'getstarted', component: <GetStarted/>, loader: async () => {
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
            } 

          }

          if(decoded_payload['req'] === 'S') {
            return redirect('/dashboard')
          } else if(decoded_payload['req'] === 'P') {
            return redirect('/dashboard')  
          } else {

            let result = await getAllDeposits()   
            const res = await result.json() 
            let resultBalance = await userBalance() 
            const balance = await resultBalance.json()

            if(balance.data[0].balance >= parseFloat(localStorage.getItem('m_b'))) { 
              return redirect('/dashboard')   
            }

            if(res['data'].length > 0) {

              if((res['data'].length === 1 && res['data'][0]['status'] === 'Pending')) {
                return redirect('/dashboard') 
              } else if(res['data'].length === 1 && res['data'][0]['status'] === 'Decline') {
                return null 
              } else {

                const success_deposit = res['data'].filter(item=>(item.status === 'Success') && (item.type !== 'singupBonus')) 
                if(success_deposit.length === 0) {

                  if((res['data'][res['data'].length - 1]['status'] === 'Pending')) {
                    return redirect('/dashboard') 
                  } else if(res['data'][res['data'].length - 1]['status'] === 'Decline') {
                    return null 
                  } else { 
                    return null 
                  }

                } else {
                  return redirect('/dashboard') 
                } 
              } 
            } else {
                return null 
            }  
          }

          return null  
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    } },   
    { url: '/referredusers', component: <ReferredUsersList/>, loader: async () => {
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
            } 
          } 
          if(decoded_payload['req'] !== 'S') {
            const fetch_card_request = await findRequestCard()
            const result_fetch_card_request = await fetch_card_request.json()  

            if(result_fetch_card_request['data'].length > 0) {
              if(result_fetch_card_request['data'][0]['requesteStatus'] === 'Success') {
                return null
              } else {
                return redirect('/dashboard')  
              }
            } else {
              return redirect('/dashboard') 
            }
          }
          

          return null 


        } else {
          return redirect('/login') 
        }
      } catch (err) {

      }
    }}, 
    { url: '/welcomereferral', component: <WelcomeReferral/>, loader: async ()=> {
        try {
          if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
              } 

            }
            if(decoded_payload['req'] !== 'S') {
              const fetch_card_request = await findRequestCard()
              const result_fetch_card_request = await fetch_card_request.json() 

              if(result_fetch_card_request['data'].length > 0) {
                if(result_fetch_card_request['data'][0]['requesteStatus'] !== 'Success') {
                  return redirect('/dashboard')  
                }
              } else {
                return redirect('/dashboard') 
              }
            }

            const fetch_all_referral_codes = await getReferralCode()
            const result_fetch_all_referral_codes = await fetch_all_referral_codes.json() 

            if(result_fetch_all_referral_codes['data'].length > 0) { 
              return {code: result_fetch_all_referral_codes['data'][0]['referralCode'], expiration_date: result_fetch_all_referral_codes['data'][0]['expireAt']['date']}  
            } else {
              return {code: null}    
            }
            return null 
          } else {
            return redirect('/login') 
          }
        } catch(err) {

        }
    } },     
    { url: 'transactionshistory', component: <TransactionsHistory/> , loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 

          }

          let fetch_deposits = await getAllDeposits() 
          const result = await fetch_deposits.json()
          if(result['data'].length > 0) { 
            return null
          } else {
            return redirect('/dashboard') 
          } 
          
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    } },
    { url: 'userguide', component: <Instruction/> , loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
            } 

          }
  
          return null 
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    } },   
    { url: 'topupcard', component: <TopUpCard />, loader: async ()=> {
    
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 
            
          }
  
          let resultFindRequest = await findRequestCard()
          // If fetch returns 401 
          if(resultFindRequest.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
              resultFindRequest = await findRequestCard()    
            }

          }
          const requestCardStatus = await resultFindRequest.json()
          if(requestCardStatus.data.length > 0) {
            if(requestCardStatus.data[0].requesteStatus === 'Success') {
              return null 
            } else if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            }
          } else {
            return redirect('/ordercard') 
          }
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
   }},    
    { url: 'successresetpassword', component: <ResetPasswordSuccess />, loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
            } 

          }
  
          return null 
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'loggedresetpassword', component: <ResetPasswordPage />, loader: async ()=> {
      
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
            } 

          }
  
          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'depositfund', component: <DepositFund />, loader: async ()=>{
       
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 

            const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            if(resultRefresh.status === 401) {
              killToken()
              return redirect('/login') 
            }

            const result = await resultRefresh.json() 

            let tokenPayload = result.token.split('.')[1] 

            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
            
            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(result.token, result.refresh_token) 
              // return null  
            } 

          } 
  
          let resultBalance = await userBalance() 
          // If fetch returns 401 
          if(resultBalance.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              resultBalance = await userBalance() 
            }

          }

          const balance = await resultBalance.json()

          let resultFindRequest = await findRequestCard() 
          // If fetch returns 401 
          if(resultFindRequest.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              resultFindRequest = await findRequestCard() 
            }

          }

          const requestCardStatus = await resultFindRequest.json()

          let result = await getAllDeposits()  
          // If fetch returns 401 
          if(result.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              result = await getAllDeposits()  
            }

          }

          const res = await result.json()

          if(requestCardStatus.data.length === 0) {
            if(balance.data[0].balance >= parseFloat(localStorage.getItem('m_d'))) { 
                return {normal_deposit: true}  
            } 

            if(res['data'].length > 0) {

              if((res['data'].length === 1 && res['data'][0]['status'] === 'Pending')) {
                return redirect('/thankyoufordeposit')
              } else if((res['data'].length === 1 && res['data'][0]['status'] === 'Decline')) {
                return {normal_deposit: false} 
              } else {

                const success_deposit = res['data'].filter(item=>(item.status === 'Success') && (item.type !== 'singupBonus')) 

                if(success_deposit.length === 0) {

                  if((res['data'][res['data'].length - 1]['status'] === 'Pending')) {
                    return redirect('/thankyoufordeposit')
                  } else if(res['data'][res['data'].length - 1]['status'] === 'Decline') {
                    return  {normal_deposit: false} 
                  } else {
                    return {normal_deposit: false} 
                  }
                } else {
                  return {normal_deposit: true} 
                } 
              }

            } else { 
              return  {normal_deposit: false}  
            }

          } else if(requestCardStatus.data.length >= 1) {
            return {normal_deposit: true} 
          } 
          
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
      
        }},
    { url: 'contactus', component: <Contact />, loader: async ()=> {
      
      try {
        if(localStorage.getItem('u_t_p')) { 
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
              } 

          } 
  
          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'thankyoufordeposit', component: <ThankYouForDeposit />, loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) { 
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
              } 

          }
  
          return null 
        } else {
          return redirect('/login') 
        } 
      } catch(err) {

      }
    }},
    { url: 'thankyouforcardorder', component: <ThankYouOrderCard />, loader: async ()=> {
       
      try {
        if(localStorage.getItem('u_t_p')) { 
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
              }

          } 
  
          return null
        } else {
          return redirect('/login') 
        }
      } catch(err) {

      }
    }}, 
    { url: 'ordercard', component: <OrderCard />, loader: async ()=>{
      
        try {
          if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              }
            }
    
            let resultBalance = await userBalance()
            // // If fetch returns 401 
            // if(resultBalance.status === 401) {
            //   const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //   // If refresh token returns 401
            //   if(refreshTokenResult.status === 401) {
            //     killToken()
            //     return redirect('/login')
            //   } 

            //   // If refresh token is successfull 
            //   const payloadRefreshToken = await refreshTokenResult.json()   

            //   let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //   // Payload of new generated token 
            //   let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //   if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //     killToken()
            //     return redirect('/login') 
            //   } else {
            //     saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
            //     resultBalance = await userBalance()  
            //   }

            // }

            const balance = await resultBalance.json()
            let resultFindRequest = await findRequestCard()
            // // If fetch returns 401 
            // if(resultFindRequest.status === 401) {
            //   const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //   // If refresh token returns 401
            //   if(refreshTokenResult.status === 401) {
            //     killToken()
            //     return redirect('/login')
            //   } 

            //   // If refresh token is successfull 
            //   const payloadRefreshToken = await refreshTokenResult.json()   

            //   let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //   // Payload of new generated token 
            //   let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //   if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //     killToken()
            //     return redirect('/login') 
            //   } else {
            //     saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
            //     resultFindRequest = await findRequestCard() 
            //   }

            // }

            const requestCardStatus = await resultFindRequest.json()

            let result = await getAllDeposits() 
            // // If fetch returns 401 
            // if(result.status === 401) {
            //   const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            //   // If refresh token returns 401
            //   if(refreshTokenResult.status === 401) {
            //     killToken()
            //     return redirect('/login')
            //   } 

            //   // If refresh token is successfull 
            //   const payloadRefreshToken = await refreshTokenResult.json()   

            //   let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            //   // Payload of new generated token 
            //   let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            //   if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            //     killToken()
            //     return redirect('/login') 
            //   } else {
            //     saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
            //     result = await getAllDeposits()   
            //   }

            // }

            const res = await result.json()

            if(decoded_payload['req'] === 'S') {
              return redirect('/mycard')
            }
            // if(decoded_payload['req'] === 'P') {
            //   return redirect('/thankyouforcardorder')
            // }

            if(requestCardStatus.data.length === 0) {
              if(balance.data[0].balance >= parseFloat(localStorage.getItem('m_b'))) { 
                  return {normal_deposit: true}  
              }

              if(res['data'].length > 0) {

                if((res['data'].length === 1 && res['data'][0]['status'] === 'Pending')) {
                  return redirect('/thankyoufordeposit') 
                }
                else if((res['data'].length === 1 && res['data'][0]['status'] === 'Decline')) {
                  return balance.data[0].balance               
                } else {
                  const success_deposit = res['data'].filter(item=>(item.status === 'Success') && (item.type !== 'singupBonus'))
                  if(success_deposit.length === 0) {
                    if((res['data'][res['data'].length - 1]['status'] === 'Pending')) {
                      return redirect('/thankyoufordeposit')
                    } else if(res['data'][res['data'].length - 1]['status'] === 'Decline') {
                      return balance.data[0].balance 
                    } else {
                      return balance.data[0].balance 
                    }
                  } else {
                    return null   
                  } 
                } 
                
              } else { 
                return balance.data[0].balance
              }
              
            } else if(requestCardStatus.data.length >= 1) { 
              if(requestCardStatus.data[0].requesteStatus === 'Pending') {
                return redirect('/thankyouforcardorder') 
              } else if(requestCardStatus.data[0].requesteStatus === 'Success') {
                return redirect('/mycard') 
              }
              return {balance: balance.data[0].balance, status: requestCardStatus.data[0].requesteStatus}
            } else {
              return null
            } 
            } else {
              return redirect('/login')
            }
        } catch(err) {

        }
        }
    },

    { url: 'profile', component: <MyInfo/>  , loader: async ()=>{
      

      try {
        if(localStorage.getItem('u_t_p')) {  
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
          if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              } 
          }
           
          let resultFindRequest = await findRequestCard() 
          // If fetch returns 401 
          if(resultFindRequest.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              resultFindRequest = await findRequestCard() 
            }

          } 

          const requestCardStatus = await resultFindRequest.json()
          if(requestCardStatus.data.length === 0) {
            return redirect('/ordercard')
          } else if(requestCardStatus.data.length === 1) {
            if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            } else if(requestCardStatus.data[0]['requesteStatus'] === 'Success') {
              return null 
            }
          }
          return null
        } else {
          return redirect('/login')
        } 
      } catch(err) {

      }
       
    }},
    { url: 'dashboard', component: <Dashboard/>, loader: async ()=>{
       
        try {
          if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) { 
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }

              const result = await resultRefresh.json() 

              let tokenPayload = result.token.split('.')[1] 

              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              }
            }
            let resultBalance = await userBalance()
            // If fetch returns 401 
            if(resultBalance.status === 401) {
              const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              // If refresh token returns 401
              if(refreshTokenResult.status === 401) {
                killToken()
                return redirect('/login')
              } 

              // If refresh token is successfull 
              const payloadRefreshToken = await refreshTokenResult.json()   

              let tokenPayload = payloadRefreshToken.token.split('.')[1] 

              // Payload of new generated token 
              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
                resultBalance = await userBalance() 
              }

            }

            const balance = await resultBalance.json()

            let resultFindRequest = await findRequestCard() 
            // If fetch returns 401 
            if(resultFindRequest.status === 401) {
              const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              // If refresh token returns 401
              if(refreshTokenResult.status === 401) {
                killToken()
                return redirect('/login')
              } 

              // If refresh token is successfull 
              const payloadRefreshToken = await refreshTokenResult.json()   

              let tokenPayload = payloadRefreshToken.token.split('.')[1] 

              // Payload of new generated token 
              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
                resultFindRequest = await findRequestCard() 
              }

            }

            const requestCardStatus = await resultFindRequest.json()
            //console.log(balance.data[0].balance, requestCardStatus) 
            let result = await getAllDeposits()  
            // If fetch returns 401 
            if(result.status === 401) {
              const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

              // If refresh token returns 401
              if(refreshTokenResult.status === 401) {
                killToken()
                return redirect('/login')
              } 

              // If refresh token is successfull 
              const payloadRefreshToken = await refreshTokenResult.json()   

              let tokenPayload = payloadRefreshToken.token.split('.')[1] 

              // Payload of new generated token 
              let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
                result = await getAllDeposits() 
              }

            }

            const res = await result.json()

            if(requestCardStatus.data.length === 0) {
              if(balance.data[0].balance >= parseFloat(localStorage.getItem('m_b'))) { 
                  return {normal_deposit: true}  
              } 

              if(res['data'].length > 0 ) { 
                if((res['data'].length === 1 && res['data'][0]['status'] === 'Pending')) {
                  return `${balance.data[0].balance}`

                } else if(res['data'].length === 1 && res['data'][0]['status'] === 'Decline') {
                  return redirect('/getstarted')
                } else {
                    
                  const success_deposit = res['data'].filter(item=>(item.status === 'Success') && (item.type !== 'singupBonus'))  

                  if(success_deposit.length === 0) {
                    if((res['data'][res['data'].length - 1]['status'] === 'Pending')) {
                      return `${balance.data[0].balance}`

                    } else if(res['data'][res['data'].length - 1]['status'] === 'Decline') {
                      return redirect('/getstarted') 

                    } else {
                      return redirect('/getstarted') 
                    } 
                  } else {
                     return {normal_deposit: true} 
                  }
                }
              } else {
                return redirect('/getstarted')

              }
    
            } else if(requestCardStatus.data.length >= 1) {
              if(requestCardStatus.data[0]['requesteStatus'] === 'Success') {
                return {normal_deposit: true, has_card: true} 
              } else {
                return {normal_deposit: true}   
              }
            } 
            } else {
              return redirect('/login')  
            } 
        } catch(err) {

        }
      } 
    },    
    
    { url: 'mycard', component: <MyCard />, loader:  async ()=>{
      
      try {
        if(localStorage.getItem('u_t_p')) {
          const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))  
          if(decoded_payload['exp']*1000 < new Date().valueOf()) {
              const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})
              if(resultRefresh.status === 401) {
                killToken()
                return redirect('/login') 
              }
              const result = await resultRefresh.json()   


              let tokenPayload = result.token.split('.')[1]   


              let new_decoded_payload = JSON.parse(atob(tokenPayload))    
               
              
              
              if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
                killToken()
                return redirect('/login') 
              } else {
                saveToken(result.token, result.refresh_token) 
                // return null  
              }
            
          }
  
          let resultFindRequest = await findRequestCard() 
          // If fetch returns 401 
          if(resultFindRequest.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return redirect('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return redirect('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)
              resultFindRequest = await findRequestCard() 
            }

          }

          const requestCardStatus = await resultFindRequest.json()
          if(requestCardStatus.data.length > 0) {
            if(requestCardStatus.data[0].requesteStatus === 'Success') {
              return null 
            } else if(requestCardStatus.data[0].requesteStatus === 'Pending') {
              return redirect('/thankyouforcardorder') 
            }
          } else {
            return redirect('/ordercard') 
          }
        } else {
          return redirect('/login') 
        }
          return null 
        
      } catch(err) {

      }}} 
  ]
  
  export const router = createBrowserRouter(createRoutesFromElements(
  <>
  <Route path='/' element={<Lplayout />} >
    <Route index element={<LpBody />} />
  </Route>

  <Route path='/login' element={<Login />} />
  <Route path='/register' element={<Registration />} />  
  <Route path='/resetpassword' element={<ResetPasswortEmail />} /> 
  <Route path='/newpassword' element={<ResetPasswordNewPassword />} /> 
  <Route path='/resetpasswordverification' element={<ResetPasswordOTP />} /> 
  {/* <Route path='/checkyouremail' element={<CheckEmail />} />  */}
  
  <Route  element={<MainLayout />} > 
      {allroutes.map((data, i) => (
        <Route
          key={i}
          exact
          path={`${data.url}`}
          element={data.component}
          loader={data.loader}
          errorElement={data.errorElement} 
        />
      ))}
  </Route>
  </>))


