// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcomeReferralContainer {
    width: 100%;
    background: #FFFFFF;
    /* height: calc(100vh - 27px); */
    height: 100%;  
    padding: 27px 50px;
    /* padding: 27px 41px;  */
    border-radius: 9.32px;
    box-shadow: 0 4.66px 4.66px 0 #523F690D; 
    margin-bottom: 8px;  
}

.h-50vh {
    height: 50vh; 
}

@media screen and (max-width: 1304px) {
    .welcomeReferralContainer {
        padding: 27px 25px;
    }
}
@media screen and (max-width: 1024px) {
    .welcomeReferralContainer {
        margin-bottom: 0; 
    }
}
@media screen and (max-width: 992px) {
    .welcomeReferralContainer {
        padding: 0;
        background: unset; 
    }
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/CSS/welcomereferral.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,gCAAgC;IAChC,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,qBAAqB;IACrB,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,UAAU;QACV,iBAAiB;IACrB;AACJ","sourcesContent":[".welcomeReferralContainer {\n    width: 100%;\n    background: #FFFFFF;\n    /* height: calc(100vh - 27px); */\n    height: 100%;  \n    padding: 27px 50px;\n    /* padding: 27px 41px;  */\n    border-radius: 9.32px;\n    box-shadow: 0 4.66px 4.66px 0 #523F690D; \n    margin-bottom: 8px;  \n}\n\n.h-50vh {\n    height: 50vh; \n}\n\n@media screen and (max-width: 1304px) {\n    .welcomeReferralContainer {\n        padding: 27px 25px;\n    }\n}\n@media screen and (max-width: 1024px) {\n    .welcomeReferralContainer {\n        margin-bottom: 0; \n    }\n}\n@media screen and (max-width: 992px) {\n    .welcomeReferralContainer {\n        padding: 0;\n        background: unset; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
