import React, { useState } from 'react'
import './CSS/verification.css'  
import { Modal } from 'react-bootstrap' 
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import OtpInput from '../components/OtpInput'   
import { securedLoginCheck, getConfig, saveToken } from '../security/AuthService' 
import { useReferral } from '../context/ReferralContext'
import { useActive } from '../context/ActiveContext'
import { useNavigate } from 'react-router-dom' 
import { useGoogleAuthentication } from '../context/GoogleAuthenticationContext' 
import { LocalStorage } from '../login/LocalStorage'

const Verification = ({show, title, description, loginEmail, loginPassword, handleClose}) => {          
  const [verificationError, setVerificationError] = useState('')   
      const navigate = useNavigate()
      const {handleMenuActive} = useActive()
      const { handleShowReferral } = useReferral()  
      const { enableGoogleAuthenticationHandler } = useGoogleAuthentication() 
      const closeHandler = ()=>{
        handleClose()
        if(verificationError) {
          setVerificationError('') 
        }
      }
  return (
    <Modal show={show} className='verification' backdrop='static' keyboard='false'>  
            <button id='closeVerification' onClick={closeHandler}>
              <img src='/images/dashboard/2fa/Vector (8).svg' alt='a grey cross mark' />
            </button>   

            <Modal.Body className='center flex-column'> 
                <img 
                    src='/images/dashboard/2fa/Enter OTP-cuate 1.svg' 
                    alt='A person sitting on a chair, holding a PC with the Paytaps logo and a phone, behind him a screen containing a verification text and 6 rectangular fields, the first 4 contain 5, 3, 3, 8 respectively, and the remaining 2 fields are empty without content, on the upper right edge of the screen a blue shield inside it a white check mark inside a gray circle, and next to the person there is a plant with blue leaves'  
                    draggable='false'  
                /> 
                <div className='verificationContentWrapper'>
                    <div className='verificationContentTitle'>{title}</div>
                    <p className='verificationContentDescription'>{description}</p>  
                </div> 
                <Formik
                            initialValues={{
                                otp: '',
                            }} 

                            validationSchema={YUP.object({
                                otp: YUP.string().required('Required!'),
                            })}

                            onSubmit={ async (values, {setSubmitting, resetForm})=>{
                              try { 

                                const post_login_check = await securedLoginCheck({
                                  email: loginEmail,
                                  password: loginPassword, 
                                  auth_code: values.otp   
                                })
                                const result_post_login_check = await post_login_check.json()
  

                                if(!post_login_check.ok) { 
                                  setVerificationError(result_post_login_check.error) 
                                } 

                                if(post_login_check.ok && result_post_login_check.token) {

                                  setVerificationError('') 
                                  saveToken(result_post_login_check.token, result_post_login_check.refresh_token) 

                                  sessionStorage.clear()  
                                  await LocalStorage(navigate, handleMenuActive, handleShowReferral);
                                  //   const config_result = await getConfig() 
                                //   const config_data = await config_result.json()

                                //   if(config_result.ok) {
                                //       for (let param of config_data['data']) {
                                //           if(param['name'] === 'CreditLimit') {
                                //               localStorage.setItem('c_l', param['value'])
                                //           }

                                //           if(param['name'] === 'MinBalance') {
                                //               localStorage.setItem('m_b', param['value']) 
                                //           }

                                //           if(param['name'] === 'TimeDownOTP') {
                                //               localStorage.setItem('o_t_p_t', param['value'])
                                //           }

                                //           if(param['name'] === 'MinTopup')  {
                                //               localStorage.setItem('m_t_u', param['value'])
                                //           }

                                //           if(param['name'] === 'TopupFee')  { 
                                //               localStorage.setItem('t_u_f', param['value'])
                                //           }

                                //           if(param['name'] === 'MinFirstDeposit') {
                                //               localStorage.setItem('m_f_d', param['value'])
                                //           }

                                //           if(param['name'] === 'MinDeposit')  {
                                //               localStorage.setItem('m_n_d', param['value'])
                                //           }

                                //           if(param['name'] === 'TimeDownDeposite')  {
                                //               localStorage.setItem('t_d_d', param['value'])
                                //           }

                                //           if(param['name'] === 'TopupFee50_100') {
                                //               localStorage.setItem('t_u_f_50_100', param['value']) 
                                //           }

                                //           if(param['name'] === 'TopupFee100') {
                                //               localStorage.setItem('t_u_f_100', param['value']) 
                                //           }

                                //           if(param['name'] === 'TokenCixRatio') {
                                //             localStorage.setItem('t_c_r', param['value']) 
                                //           }

                                //           if(param['name'] === 'ActiveFeesCix') {
                                //             localStorage.setItem('a_f_c', param['value']) 
                                //           } 

                                //           if(param['name'] === 'ActiveCashBack') {
                                //             localStorage.setItem('a_c_b', param['value']) 
                                //           } 
                                //       }                                          
                                      
                                //   } else {
                                //       localStorage.setItem('c_l', '100000') 
                                //       localStorage.setItem('o_t_p_t', '1')  
                                //       localStorage.setItem('m_t_u', '100')
                                //       localStorage.setItem('t_u_f', '3') 
                                //       localStorage.setItem('m_f_d', '120')
                                //       localStorage.setItem('m_n_d', '100')
                                //       localStorage.setItem('t_d_d', '10')
                                //       localStorage.setItem('t_u_f_50_100', '4')
                                //       localStorage.setItem('t_u_f_100', '3') 
                                //       localStorage.setItem('t_c_r', '0.0045')
                                //       localStorage.setItem('a_f_c', 'false')
                                //       localStorage.setItem('a_c_b', 'false') 
                                //   } 
                                //   const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 

                                //   switch(decoded_payload['req']) {
                                                
                                //     case 'N': { handleMenuActive('User Guide'); navigate('/userguide') }                     
                                //             break;
                                //     case 'P': { handleMenuActive(''); navigate('/thankyouforcardorder') } 
                                //             break; 
                                //     case 'D': { handleMenuActive('User Guide'); navigate('/userguide') }                          
                                //             break;
                                //     case 'S': {handleShowReferral();handleMenuActive('Dashboard');navigate('/dashboard')} 
                                //             break;
                                //     default:  navigate('/ordercard')
                                // }

                                }
                              } catch(err) {

                              } 
                            }}
                        >
                           
                                 {(formik) => (
                                <Form className='form' onSubmit={formik.handleSubmit}>
                                  <OtpInput length={6} 
                                      onChange={(otp) => formik.setFieldValue('otp', otp)} 
                                  />
                                  {verificationError && <div className='verificationError'>{verificationError}</div>}   
                                  <button
                                    type='submit'
                                    id='verificationVerifyNowOTP'
                                    className={`${verificationError ? 'mt-0': 'mt-27'}`} 
                                    disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting || formik.values.otp.length !== 6}  
                                  > 
                                    Verify Now
                                  </button> 
                                </Form>  
          )}
                </Formik>
                <div className={`checkSpamSendAgainWrapper ${title === 'Enter Google Authenticator Code' ? 'h-27' : 'h-auto'}`}> 
                    {title !== 'Enter Google Authenticator Code' && <span className='checkSpam'>Didn’t receive it? Check your spam folder</span>}
                </div>  
            </Modal.Body>
    </Modal>
  )
}

export default Verification    


                                                                                                                                                                                                                                                                                                                                                                                                                                                   