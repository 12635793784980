// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inviteShareLinksSpendingsContainer {
    width: 100%;
    display: flex;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 10px;   
}

.inviteShareLinksSpendingsContainer > :first-child {
    width: 60%;
}
.inviteShareLinksSpendingsContainer > :nth-child(2) {
    width: 40%;  
} 

@media screen and (max-width: 640px) {
    .inviteShareLinksSpendingsContainer {
        flex-direction: column; 
    }
    .inviteShareLinksSpendingsContainer > :first-child, .inviteShareLinksSpendingsContainer > :nth-child(2) {
        width: 100%; 
    }
}
 `, "",{"version":3,"sources":["webpack://./src/components/CSS/invitesharelinksspendings.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,SAAS;IACT,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".inviteShareLinksSpendingsContainer {\n    width: 100%;\n    display: flex;\n    gap: 15px;\n    margin-top: 20px;\n    margin-bottom: 10px;   \n}\n\n.inviteShareLinksSpendingsContainer > :first-child {\n    width: 60%;\n}\n.inviteShareLinksSpendingsContainer > :nth-child(2) {\n    width: 40%;  \n} \n\n@media screen and (max-width: 640px) {\n    .inviteShareLinksSpendingsContainer {\n        flex-direction: column; \n    }\n    .inviteShareLinksSpendingsContainer > :first-child, .inviteShareLinksSpendingsContainer > :nth-child(2) {\n        width: 100%; \n    }\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
