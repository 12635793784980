import React, { useState, useEffect } from 'react'                                                                                                              
import { Modal } from 'react-bootstrap'
import './CSS/showdetails.css'
import Button from '../components/Button' 
import { getcardDetails, getProfileData, refreshToken, killToken, saveToken } from '../security/AuthService'              
import Loader from '../jsx/pages/Loader/Loader' 
import { useNavigate } from 'react-router-dom' 
const ShowDetails = (props) => {
    const navigate = useNavigate() 
    const [data, setData] = useState({})
    const [error, setError] = useState('') 
    const [loading, setLoading] = useState(false) 
    const [isCopied, setIsCopied] = useState(false)

    useEffect(()=>{
        if(props.show) {
            getCardDetailsData() 
        }
    }, [props.show]) 

    async function getCardDetailsData () {
        try {
        setLoading(true)
        let card_details_result = await getcardDetails()
        // If fetch returns 401 
        if(card_details_result.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return navigate('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return navigate('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
              card_details_result = await getcardDetails()    
            }

        }

        const card_details_data = await card_details_result.json() 

        let name_result = await getProfileData() 
        // If fetch returns 401 
        if(name_result.status === 401) {
            const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})

            // If refresh token returns 401
            if(refreshTokenResult.status === 401) {
              killToken()
              return navigate('/login')
            } 

            // If refresh token is successfull 
            const payloadRefreshToken = await refreshTokenResult.json()   

            let tokenPayload = payloadRefreshToken.token.split('.')[1] 

            // Payload of new generated token 
            let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

            if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
              killToken()
              return navigate('/login') 
            } else {
              saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token) 
              name_result = await getProfileData()    
            }

        }
        const name_data = await name_result.json() 

        if(card_details_result.ok && name_result.ok) { 
         setData({...data, data: card_details_data['data'], firstName: name_data['data'].firstName, lastName: name_data['data'].lastName})  
        } else {
        setError('No Data')
        }
        setLoading(false) 
    } catch(err) {
        
    }
    }

    function handleCancel () {
        props.handleClosecardDetails()
    }
    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('cardNumber').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
            setIsCopied(false)
        }
    }

  return (
    <Modal show={props.show}  className='topup-modal showDetailsModal' backdrop='static' keyboard='false' >
        
        {((!loading && !error) && (Object.keys(data).length > 1)) && <><div className={`card-wrapper cardDetailsData detailsDataCard`} >
                    <figure>
                        <img src='/images/dashboard/mycard/paytaps card.png' alt="card paytaps" />
                        <span className='card-holder-name'>{data.firstName} {data.lastName}</span>   
                        <span className='card-number' id='cardNumber'>{data.data['cardNumber'] ? data.data['cardNumber'].substring(0, 4) + ' ' + data.data['cardNumber'].substring(4, 8) + ' ' + data.data['cardNumber'].substring(8, 12) + ' ' + data.data['cardNumber'].substring(12, 16) : 'XXXX XXXX XXXX XXXX'}</span> 
                        <div className='card-validity'>
                            <span>VALID THRU</span>
                            <span>{`${(data.data['cardExpMth']) ?  data.data['cardExpMth'].length < 2 ? '0' + data.data['cardExpMth'] : data.data['cardExpMth'] : 'XX'}/${data.data['cardExpYear'] ? data.data['cardExpYear'] : 'XXXX'}`}</span>  
                        </div>
                        <div className='cvvWrapper'>
                            <span>CVV</span>
                            <span>{data.data['cardCvv'] ? data.data['cardCvv'] : 'XXX'}</span>  
                        </div>
                    </figure>
                </div>
                <div>
                    <div className='showDetailsTitle'>Your Card Details.</div>
                    <div className='showDetailsDescription'>The details of your card are displayed above. You can copy your card number to link it with digital wallet services such as Google Pay, Apple Pay, or Samsung Pay. 
Use the button below to copy the number.</div> 
                </div>
                <div className='group-btn'> 
                                    <Button click={handleCancel} id="cancel">Cancel</Button>
                                    <Button btnType='submit' id='copyAddress' click={copyAddress}><span hidden={!isCopied}>Copied!</span><span hidden={isCopied}>Copy card number</span></Button> 
                                </div></> }
                                {(loading && !error) && <><div className='loaderWrapper'><Loader /></div></>} 
   </Modal> 
  )
}

export default ShowDetails  