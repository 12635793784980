import React, { useCallback } from 'react'
import Button from './Button'
import './CSS/generatelink.css'
import { useNavigate } from 'react-router-dom'
const GenerateLink = ({generateCode}) => {

  const navigate = useNavigate()
  const redirectToGlobalReferralData = useCallback(function () {
    window.scrollTo(0,0)  
    return navigate('/referredusers') 
  })
  
  return (
    <>
        <div className='generateLinkContainer'>
            <div className='generateLinkTextualContent'>
                <div className='welcomePaytpsReferralTitle'>Welcome to the Paytaps Referral</div>
                <p className='welcomePaytapsReferralDescription'>Earn rewards by inviting your friends to join Paytaps! It's simple—share your unique referral link or code, and get rewarded when your friend orders their card.
                <span>To get started, just click the button below to generate your referral link. Once your link is ready, you can share it with friends and watch the rewards roll in!</span></p>
                <div className='generateLinkGroupBtn'>
                  <Button styles='generateLinkButton' click={generateCode}>Generate Link</Button>
                  <Button imagesrc='/images/dashboard/referral/ph_user-list-bold (1).svg' altText='icon of person constructed of a circle below it an arc and in the right of it 3 recatngles with rounded corners, and the last of them has a less width than others' styles='allRefferedUsers'
                  click={redirectToGlobalReferralData}>See All Referred Users</Button>  
                </div>
            </div>
            <figure className='generateLinkIllustrationContent'>
                <img src='/images/dashboard/referral/Group 505.svg' alt="3 circles linked to each each others with lines, in each circle, there\'s an image of a person, the first holds a phone, the second holds a tablet, and the last one a pc; in the inner layout generated by these circles a paytaps card" /> 
            </figure>
        </div>
    </>
  )
}

export default GenerateLink
