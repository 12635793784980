import React from 'react'
import Snowfall from 'react-snowfall' 

const SnowFallParent = ({snowflakecount, radius}) => {
    const snowflake1 = document.createElement('img')
    snowflake1.src = '/images/landingpage/body/logo-M84r5xQ4.svg' 
    const images = [snowflake1, snowflake1] 
  return (
    <Snowfall 
        images={images} 
        style={{opacity: '0.8', zIndex: '200', position: 'fixed', width: '100vw', height: '100vh'}}     
        snowflakeCount={snowflakecount}  
        radius={radius}  
    />
  )
}

export default SnowFallParent