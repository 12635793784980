import React, { createContext, useState, useContext } from "react";
export const EnableCountDownContext = createContext(null);


const EnableCountDownContextProvider = (props) => {
    const [stateCountDown, setStateCountDown] = useState(false)
     return (
        <EnableCountDownContext.Provider
          value={{
            stateCountDown,
            setStateCountDown
        }}
        >
          {props.children}
        </EnableCountDownContext.Provider>
     )
}

export default EnableCountDownContextProvider

export function useStateCountDown () {
    const {stateCountDown, setStateCountDown} = useContext(EnableCountDownContext)
    return {stateCountDown, setStateCountDown} 
}

