// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referralWelcomeImageContainerImage {
    width: 100%; 
}
.referralWelcomeImageContainerImage > img  {
    width: 100%; 
}

.referralWelcomeImageContainerRes > img {
    width: 100%; 
}
@media screen and (min-width: 641px) {
    .referralWelcomeImageContainerRes {
        display: none; 
    }
}
@media screen and (max-width: 640px) {
    .referralWelcomeImageContainerImage {
        display: none; 
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/referralwelcomeimage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;AACA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".referralWelcomeImageContainerImage {\n    width: 100%; \n}\n.referralWelcomeImageContainerImage > img  {\n    width: 100%; \n}\n\n.referralWelcomeImageContainerRes > img {\n    width: 100%; \n}\n@media screen and (min-width: 641px) {\n    .referralWelcomeImageContainerRes {\n        display: none; \n    }\n}\n@media screen and (max-width: 640px) {\n    .referralWelcomeImageContainerImage {\n        display: none; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
