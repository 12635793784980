// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoContainer {
    width: 100%;
    position: relative;
    z-index: 2;
    background: white;
    margin-top: -23px;
}
#paytapsBlackFridayVideo {
    width: 100%;
    max-width: 1140px;
    display: block;
    margin: auto;
    position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/landingpage/LPBody/components/CSS/video.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".VideoContainer {\n    width: 100%;\n    position: relative;\n    z-index: 2;\n    background: white;\n    margin-top: -23px;\n}\n#paytapsBlackFridayVideo {\n    width: 100%;\n    max-width: 1140px;\n    display: block;\n    margin: auto;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
