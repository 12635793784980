import React from 'react'
import './CSS/ordercardnow.css'
import Button from './Button'    
import { useNavigate } from 'react-router-dom'
import { useActive } from '../context/ActiveContext' 
const OrderCardNow = () => {
  const navigate = useNavigate()
  const { handleMenuActive } = useActive() 
  function redirectToOrderCard () {
    handleMenuActive('') 
    return navigate('/ordercard') 
  }
  return (
    <div className='orderCardNowContainer'>
        <div className='orderCardNowTextualContentWrapper'>
            <div className='orderYouCardTitle'>Order Your Card</div>
            <p className='orderYourCardDescription'>You’re now ready to order your Paytaps Card and start spending crypto anywhere, anytime.</p> 
            <Button id='orderNow' click={redirectToOrderCard}>Order Now</Button>             
        </div>

        <figure className='orderCardNowIllustrationWrapper'>
            <img src='/gif/paytaps gif 1.gif' alt='an animated gif that contains; a person holding a phone behind him a gray wallet, and on his left side a big phone on his screen, a paytaps card, a top up button, at the bottom of the top up button, a long threaded paper; on the left side of the phone 8 blue coins' draggable={false} />                                                                                                                                                       
        </figure>
    </div>
  )
}

export default OrderCardNow 