import React, { useCallback } from 'react'
import './CSS/banner.css' 
import { useNavigate } from 'react-router-dom'
import { useActive } from '../context/ActiveContext' 
import { useReferral } from '../context/ReferralContext'
const Banner = () => {
  const { showReferral } = useReferral() 
  const { handleMenuActive } = useActive() 
  const navigate = useNavigate() 
  const redirectToReferral = useCallback(function () {
    if(!showReferral) {
      return handleMenuActive('Dashboard')  
    } else {
      handleMenuActive('Referral') 
      window.scrollTo(0,0) 
      return navigate('/welcomereferral') 
    }   
  }, [])   

  return (
    <>
        <img id='bannerWEB' 
        src='/images/dashboard/banner/PayTaps CT - 1288x120@2x.png'  
        alt='An area inside it; a Paytabs logo next to it, a Paytaps Card Christmas offer title; next to it, a description of the offer starting with enjoy a 30 USDT signup bonus..., next to the description, a deer logo in a mixed color between purple and blue, on its left side a rounded rectangle (button) inside of it, a text "Join Now", next to the rectangle a birthday balloon, a Paytabs card and a Christmas tree in a mixed color between purple and blue' 
        onClick={redirectToReferral} /> 

        <img id='bannerMOBILE' 
        src='/images/dashboard/banner/PayTaps CT - 640x120@2x.png'   
        alt='An area inside it; a Paytabs logo below it, a Paytaps Card Christmas offer title; followed by its description starting with enjoy a 30 USDT signup bonus... Next to them on the left side, a big birthday balloon in a mixed color between purple and blue below it, a rounded rectangle (button) inside it, a text "Join Now". Next to the birthday balloon and the rectangle, a deer logo in a mixed color between purple and blue, a Paytabs card and a Christmas tree in a mixed color between purple and blue' 
        onClick={redirectToReferral} />                                                                                                                                                                    
    </>
  )
}

export default Banner 

