import React, { useState, useEffect, useCallback, useRef, memo } from 'react' 
import './CSS/topup.css'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button' 
import { convertToUSDT, requestTopUp } from '../security/AuthService' 
import { useNavigate } from 'react-router-dom' 
import { useActive } from '../context/ActiveContext' 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 

const Topup = memo((props) => {
    const [amount, setAmount] = useState('--')
    const [totalAmount, setTotalAmount] = useState('--')
    const [feesCIX, setFeesCIX] = useState(null)  
    const navigate = useNavigate() 
    const { handleMenuActive } = useActive()  

    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value) 
        state.setFieldTouched(e.target['name'])
    }

    useEffect(()=>{
        try {
            if(document.getElementById('topup_amount')) {
                document.getElementById('topup_amount').addEventListener('keyup', ()=>{
                    handleChange()  
                })}
        } catch(err) {

        }

    } )  

    async function handleChange() {
        
       try {
        
            let topup_fee = parseFloat(localStorage.getItem('t_u_f'))
            if(document.getElementById('topup_amount').value === '') {
                localStorage.setItem('t_u_f', 0)   
                setAmount('--')
                setTotalAmount('--') 
                setFeesCIX(null) 
            }
            if( /^[0-9\.]+$/.test(document.getElementById('topup_amount').value)) {
                
                     
                    let convert = {
                        api_call: await convertToUSDT({
                            amount: parseFloat(document.getElementById('topup_amount').value) 
                        })
                    }
                    const renew = async () =>{
                        return await convertToUSDT({
                            amount: parseFloat(document.getElementById('topup_amount').value) 
                        })
                    }
                    // If fetch returns 401 
                    if(convert.api_call.status === 401) {  
                        if(await refreshTokenWithAPICallHandler(convert, renew) === 'REDIRECT_TO_LOGIN') {
                            return navigate('/login') 
                        }

                    }
                    const convert_data = await convert.api_call.json()

                    if(document.getElementById('topup_amount').value === '') {
                        setAmount('--')
                        setTotalAmount('--')
                        setFeesCIX(null)                                                                                        
                    } else {
                        if(!/^[0-9\.]+$/.test(document.getElementById('topup_amount').value)) {
                            setAmount('--')
                            setTotalAmount('--')
                            setFeesCIX(null)
                            return
                        }

                        if(document.getElementById('topup_amount').value >= 100) { 
                            localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_100')) 
                            topup_fee = parseFloat(localStorage.getItem('t_u_f')) 
                        } else if((document.getElementById('topup_amount').value >= 50) && (document.getElementById('topup_amount').value < 100)) {
                            localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_50_100'))  
                            topup_fee = parseFloat(localStorage.getItem('t_u_f'))  
                        } else {
                            localStorage.setItem('t_u_f', 0)
                            topup_fee = 0  
                        }

                        setAmount((parseFloat(document.getElementById('topup_amount').value))) 
                        setTotalAmount((((parseFloat(convert_data['data']['result'])*topup_fee)/100) + parseFloat(convert_data['data']['result'])))    

                        
                        if(localStorage.getItem('a_f_c') === 'true') { 
                            let topup_fee_amount_usdt = (parseFloat(convert_data['data']['result'])*topup_fee)/100
                            const conversion_rate_cix_usdt = parseFloat(localStorage.getItem('t_c_r'))
                            const fees_usdt_to_cix = topup_fee_amount_usdt / conversion_rate_cix_usdt
                            if(Math.sign(props.balanceCix - fees_usdt_to_cix) === 1) {
                               setFeesCIX(fees_usdt_to_cix)
                            } else if(Math.sign(props.balanceCix - fees_usdt_to_cix) === -1) {
                               setFeesCIX(null) 
                            }

                        } else {
                            if(feesCIX) {
                               setFeesCIX(null)
                            }
                        }

                    }
            } else {
                localStorage.setItem('t_u_f', 0)   
                setAmount('--')
                setTotalAmount('--')
                setFeesCIX(null) 

            } 
       } catch(err) {
       }
    } 
    const handleCancel = useCallback(async function() {
        let path = window.location.pathname;
        path = path.split("/");
        path = path[path.length - 1] 

        if(path === 'topupcard') {
            navigate('/mycard')   
            handleMenuActive('My Card')    
            handleMenuActive('My Card')      

        }
        setAmount('--')
        setTotalAmount('--')
        setFeesCIX(null)
        props.handleClose()
        await new Promise((resolve, reject)=>{
            setTimeout(()=>{
                resolve(localStorage.setItem('t_u_f', 0))
            }, 100)
        })
    }, [])  
    
  return (
     <Modal show={props.show} onHide={props.handleClose} className='topup-modal topup-scroll' backdrop='static' keyboard='false' >
        <div className='modal-header'>
            <div className='topup-title'>Top-up</div>
            <div className='topup-description'>Enter the amount in USD you want to add. This amount will be available on your card in USD.</div>
        </div> 
    
        <Formik
                initialValues={{
                    topup_amount: ''
                }}
                validateOnChange={ true }
                validateOnBlur={ true } 
                validationSchema={YUP.object({
                    topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid top-up amount</span>}).test('topup', `Please enter at least ${parseFloat(localStorage.getItem('m_t_u'))}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_t_u'))),   
                })}

                onSubmit={async (values)=>{ 
                    try {
                        let path = window.location.pathname;
                        path = path.split("/");
                        path = path[path.length - 1]   
                        
                        let topup = {
                            api_call: await requestTopUp({
                            amountFiat: parseFloat(values.topup_amount),
                            amountCryptoTotal: parseFloat(totalAmount),
                            topupFeeCrypto: feesCIX ? parseFloat(feesCIX) : (parseFloat(totalAmount) - parseFloat(values.topup_amount)), 
                            currencyCrypto: feesCIX ? 'cix' : 'usdt' 
                        })
                        }
                        const renew = async () => {
                            return await requestTopUp({
                            amountFiat: parseFloat(values.topup_amount),
                            amountCryptoTotal: parseFloat(totalAmount),
                            topupFeeCrypto: feesCIX ? parseFloat(feesCIX) : (parseFloat(totalAmount) - parseFloat(values.topup_amount)), 
                            currencyCrypto: feesCIX ? 'cix' : 'usdt'
                        })
                        }
                        // If fetch returns 401  
                        if(topup.api_call.status === 401) {
                            if(await refreshTokenWithAPICallHandler(topup, renew) === 'REDIRECT_TO_LOGIN') {
                                return navigate('/login') 
                            } 
                        }

                        if(topup.api_call.ok) {
                            const result = await topup.api_call.json()   
                            if(path === 'topupcard') {    
                                props.handleClose() 
                            } 
                            if(path === 'mycard') {
                                props.handleClose()
                                props.updateBalance(result['data']['balance']) 
                            }
                            if(path === 'dashboard') {
                                props.handleClose()   
                                props.updateBalance(result['data']['balance']) 
                                props.updateBalanceUsd(result['data']['balance'])
                                props.updateCixBalance(result['data']['balanceCix'], result['data']['balanceCix'] * parseFloat(localStorage.getItem('t_c_r'))) 
                            }
                            props.handleShowTopupSuccess()  
                            await new Promise((resolve, reject)=>{
                                setTimeout(()=>{
                                    resolve(localStorage.setItem('t_u_f', 0))
                                }, 100)
                            }) 
                        }

                        if(!topup.api_call.ok) {
                            props.handleClose()
                            props.handleShowInsufTopupBalance()  
                        }
                        setAmount('--')
                        setTotalAmount('--')
                        setFeesCIX(null)
                        } catch(err) {
                        }
                }}
                        >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                
                                <TextInput 
                                    name='topup_amount' 
                                    id='topup_amount' 
                                    label='Enter amount' 
                                    inputtype='text' 
                                    onKeyUp={keyUpHandler} 
                                    currency='usd'  
                                />   
                                <div className='amount-fees-wrapper'> 
                                    <div className='amount-fees-row'><span>Available balance</span><span>{`${props.balanceUSDT.toFixed(2)}`} <strong>USDT</strong> | {props.balanceUsd.toFixed(2)} <strong>USD</strong></span></div>
                                    {localStorage.getItem('a_f_c') === 'true' && <div className='amount-fees-row'><span>Available CIX</span><span>{`${props.balanceCix.toFixed(2)}`} <strong>CIX</strong></span> </div>}                                                                               
                                    <div className='amount-fees-row'><span>Amount</span><span>{!isNaN(parseFloat(amount)) ? parseFloat(amount).toFixed(2) : '--'} USD</span></div>
                                    <div className='amount-fees-row'><span>Fees</span><span>{localStorage.getItem('t_u_f')}%</span></div> 
                                    <div className='amount-fees-row' style={feesCIX ? {alignItems: 'center'} : {}}> 
                                        <span>Total</span>
                                        {!feesCIX ? 
                                        <span>{!isNaN(parseFloat(totalAmount)) ? parseFloat(totalAmount).toFixed(2) : '--'} USDT</span> : 
                                        <span style={{display: 'flex', flexDirection: 'column', textAlign: 'right'}}>  
                                            <span>{!isNaN(parseFloat(amount)) ? parseFloat(amount).toFixed(2) : '--'} USDT</span> 
                                            <span style={{textAlign: 'center'}}>+</span> 
                                            <span>{!isNaN(parseFloat(feesCIX)) ? parseFloat(feesCIX).toFixed(2) : '--'} CIX</span>  
                                        </span>
                                        }
                                    </div>                                                                                                   
                                </div> 
                                <div className='transaction-fee-notification'>
                                    * Our transaction fee are included. <span>See transaction fee</span>
                                </div>
                                
                                <div className='group-btn'>
                                    <Button click={handleCancel} id="cancel">Cancel</Button>
                                    <Button btnType='submit' id='confirm_topup' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting }>Confirm</Button>     
                                </div>
                                    
                            </Form>)}}
        </Formik> 
   </Modal> 
  )
}
) 

export default Topup 




