import React from 'react'
import balancecixstyles from './CSS/balancecix.module.css' 

const BalanceCix = ({balanceCix}) => {
  return (
    <section className={balancecixstyles.balanceCixContainer}>
        <div className={balancecixstyles.balanceCixLeftContent}>
            <span className={balancecixstyles.tokenExchangeTitleText}>Token Exchange</span>
            <span className={balancecixstyles.tokenExchangeDescriptionTextAndValues}>Lorem ipsum dolor sit amet, ipsum</span>
            <span className={balancecixstyles.tokenExchangeDescriptionTextAndValues}>{parseFloat(3 / localStorage.getItem('t_c_r')).toFixed(2)} CIX = 3 USDT</span>                     
        </div>
        <div className={balancecixstyles.balanceCixRightContent}>
            <div className={balancecixstyles.balanceCixValueCixUsdt}>
                <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                    <img src='/images/dashboard/dashboard/Group 1 (2).svg' alt='a white capital c above it white line in a grey circle' draggable='false' /> 
                    <span className={balancecixstyles.currency}>CIX</span>
                </div>
                <div style={{marginTop: '8px'}}>
                    <span className={balancecixstyles.currencyValue}>{!isNaN(parseFloat(balanceCix.availableCix)) ? parseFloat(balanceCix.availableCix).toFixed(2) : '--'}</span>
                    <span className={balancecixstyles.currencyName}> CIX</span>
                </div>
            </div>
            <img 
                src='/images/dashboard/dashboard/Group 624.svg' 
                alt='2 rows in reverse orders with white border, with the first one is oriented to left and the second to right in a grey circle' 
                draggable='false' 
                className={balancecixstyles.conversion}
            /> 
            <div className={balancecixstyles.balanceCixValueCixUsdt}>
                <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                    <img src='/images/dashboard/dashboard/div.nk-wgw-icon (1).svg' alt='tether icon with white border in a gery circle' draggable='false' /> 
                    <span className={balancecixstyles.currency}>USDT</span>
                </div>
                <div style={{marginTop: '8px'}}>
                    <span className={balancecixstyles.currencyValue}>{!isNaN(parseFloat(balanceCix.availableCixinUsdt)) ? parseFloat(balanceCix.availableCixinUsdt).toFixed(2) : '--'}</span>
                    <span className={balancecixstyles.currencyName}> USDT</span>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BalanceCix
