// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referredUsersListContainer {
    width: 100%;
}
.backFromReferredUsersList {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 15px; 
}
#backFromReferredUsers {
    border: none;
    background: transparent;
}
.referredUsersList {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    color: #000000;
}

.referralChunkDataWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(343px, 1fr));
    gap: 20px; 
}
@media screen and (max-width: 372px) {
    .referralChunkDataWrapper {
        grid-template-columns: 100%;  
    }
}`, "",{"version":3,"sources":["webpack://./src/dashboard/CSS/referreduserslist.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,2DAA2D;IAC3D,SAAS;AACb;AACA;IACI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".referredUsersListContainer {\n    width: 100%;\n}\n.backFromReferredUsersList {\n    display: flex;\n    align-items: center;\n    gap: 25px;\n    margin-bottom: 15px; \n}\n#backFromReferredUsers {\n    border: none;\n    background: transparent;\n}\n.referredUsersList {\n    font-size: 30px;\n    font-weight: 600;\n    line-height: 36px;\n    color: #000000;\n}\n\n.referralChunkDataWrapper {\n    width: 100%;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(343px, 1fr));\n    gap: 20px; \n}\n@media screen and (max-width: 372px) {\n    .referralChunkDataWrapper {\n        grid-template-columns: 100%;  \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
