import React, { useState, useEffect } from 'react';import './CSS/OtpInput.css';  
const OtpInput = ({ length, onChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;      
      setOtp(newOtp);
      onChange(newOtp.join(''));

      // Move focus to next inputif (index < length - 1) {
      if(index < length - 1) {
        e.target.nextSibling.focus();
      }
      }    
    }   


    const handleKeyDown = (e, index) => {     
      if (e.key === 'Backspace') {       
        e.preventDefault();       
        const newOtp = [...otp];       
        if (newOtp[index] !== '') {         
            newOtp[index] = ''; 
        // Clear current input      
        } else if (index > 0) {        
            e.target.previousSibling.focus();         newOtp[index - 1] = ''; 
        // Clear previous input
        } 
      setOtp(newOtp); onChange(newOtp.join('')); 
      } 
    } 

     
     useEffect(()=>{
        document.querySelector('.otp-input').addEventListener('paste', async (e)=>{  
          try {
            e.preventDefault() 
            getClipboardData().then((res)=> {
              if(/^[0-9]{6}$/.test(res)) {
                setOtp(res.split(''));
                const inputsNumber = document.querySelectorAll('.otp-input > input[type="text"]').length 
                document.querySelectorAll('.otp-input > input[type="text"]')[inputsNumber - 1].focus()
                onChange(res); 
              }  
              }).catch((err)=>console.log(err))

          } catch (err) {
            
          } 
        }) 
      }, []) 
         

      async function getClipboardData () {  
        return new Promise((resolve, reject)=>{
          navigator.clipboard.readText().then((res)=> resolve(res)).catch((err)=> reject(err)) 
        })
      }
      
      
  return (
    <div className="otp-input">
      {otp.map((_, index) => (
        <input key={index} type="text" inputMode="numeric" maxLength="1" value={otp[index]} onChange={(e) => handleChange(e, index)} 
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
    </div>
  );
};

export default OtpInput;