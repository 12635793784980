// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bannerWEB {
    width: 100%;
    object-fit: cover; 
    margin-top: 27px;  
    cursor: pointer; 
}
#bannerMOBILE {
    display: none;   
}
@media screen and (max-width: 640px) {
    #bannerWEB {
        display: none;   
    }
    #bannerMOBILE {
        display: block;  
        width: 100%;
        object-fit: cover; 
        margin-top: 27px;  
        cursor: pointer; 
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/CSS/banner.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,cAAc;QACd,WAAW;QACX,iBAAiB;QACjB,gBAAgB;QAChB,eAAe;IACnB;AACJ","sourcesContent":["#bannerWEB {\n    width: 100%;\n    object-fit: cover; \n    margin-top: 27px;  \n    cursor: pointer; \n}\n#bannerMOBILE {\n    display: none;   \n}\n@media screen and (max-width: 640px) {\n    #bannerWEB {\n        display: none;   \n    }\n    #bannerMOBILE {\n        display: block;  \n        width: 100%;\n        object-fit: cover; \n        margin-top: 27px;  \n        cursor: pointer; \n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
