import React, { createContext, useCallback, useContext, useReducer } from "react";
import { MenuList } from "../jsx/layouts/nav/Menu";
import { useToggle } from "./ToggleContext";

export const ActiveContext = createContext(null);

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1]  

  function initialActiveLink () {
    let index_active_link = MenuList.findIndex(menulink=> (menulink.to==='/'+path))
    if(index_active_link === -1) {
      if(path === 'ordercard') {
        return 'My Card' 
      } else if(path === 'referredusers') {
        return 'Referral'  
      } else if(path === 'getstarted') {
        return 'Dashboard' 
      }
    } else {
      return MenuList[index_active_link].title
    }
  }

 const reducer = (previousState, updatedState) => ({
    ...previousState,
    ...updatedState,
  });

 const initialState = {
    active : initialActiveLink(),
    activeSubmenu : "",
  }

const ActiveContextProvider = (props) => {
  const { toggleHandler } = useToggle()
    const [state, setState] = useReducer(reducer, initialState);
    const handleMenuActive = useCallback((status) => {		
		setState({active : status});
    if(window.innerWidth < 1024) {
      toggleHandler()
    }}, [toggleHandler])  

     return (
        <ActiveContext.Provider
          value={{
            state,
            setState,
            handleMenuActive
        }}
        >
          {props.children}
        </ActiveContext.Provider>
     )
}

export default ActiveContextProvider

export function useActive () {
    const {state, setState, handleMenuActive} = useContext(ActiveContext)
    return {state, setState, handleMenuActive}
}
