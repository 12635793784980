import React, { useState, useEffect, useCallback } from 'react'  
import Topup from '../Modals/Topup'  
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up' 
import { userBalance, convertToUSDT, findRequestCard } from '../security/AuthService' 
import TopupSuccess from '../Modals/TopupSuccess'
import { useReferral } from '../context/ReferralContext'; 

const TopUpCard = () => {
    const [show, setShow] = useState(false) 
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  
    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);
    const [balanceUSDT, setBalanceUSDT] = useState(0)
    const [balanceEUR, setBalanceEUR] = useState(0)
    const [showSuccess, setShowSuccess] = useState(false)                                                                                   
    const { showReferral, handleShowReferral } = useReferral() 

    useEffect(()=>{
        getBalanceUSDTEUR() 
    }, []) 
    const handleClose = useCallback(function() {
        setShow(false) 
    }, []) 

    const handleCloseTopupSuccess = useCallback(function () {
        setShowSuccess(false) 
    }, [])
    const handleShowTopupSuccess = useCallback(function () {
        setShowSuccess(true)
    }, [])

    async function getBalanceUSDTEUR () {
        const user_balance = await userBalance()
        const balance_usdt = await user_balance.json()

        // Start of verification if referral should appear or no
            if(!showReferral) {
                handleShowReferral() 
            }
        // End   

        if(user_balance.ok) {
            setBalanceUSDT(balance_usdt['data'][0]['balance'])  
        }
        const convert = await convertToUSDT({
            amount: balance_usdt['data'][0]['balance'] 
        })
        const convert_result = await convert.json() 


        if(convert.ok) {
            const balance_eur = balance_usdt['data'][0]['balance'] / convert_result['data']['info']['rate'] 
            setBalanceEUR(balance_eur)       
        } 
        setShow(true) 
    }

    
  return (
    
    <>
        <Topup show={show} handleClose={handleClose} handleShowInsufTopupBalance={handleShowInsufTopupBalance} balanceEUR={balanceEUR} balanceUSDT={balanceUSDT} handleShowTopupSuccess={handleShowTopupSuccess} />                                                          
        <InsufficientBalance_top_up show={showInsufficientTopupBalance} handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} handleShowInsufTopupBalance={handleShowInsufTopupBalance} />  
        <TopupSuccess show={showSuccess} handleClose={handleCloseTopupSuccess} />                                                                                                                                                                                                                    
    </>
  )
}

export default TopUpCard  
