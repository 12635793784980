import React from 'react' 
import { Helmet } from 'react-helmet'  

const SEO = ({title, description, ogTitle, ogDescription, ogUrl, twitterTitle, twitterDescription, canonicalLink}) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} /> 
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />  
        <meta property="og:image" content="https://paytaps.com/images/dashboard/dashboard/Open-Graph-img-paytaps.png"/> 
        <meta property="og:image:secure_url" content="https://paytaps.com/images/dashboard/dashboard/Open-Graph-img-paytaps.png" /> 
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />   
        <meta property="og:url" content={ogUrl} />    
        <meta property="og:type" content="website" />   
        <meta name="twitter:title" content={twitterTitle} /> 
        <meta name="twitter:description" content={twitterDescription} /> 
        <meta name="twitter:image" content="https://paytaps.com/images/dashboard/dashboard/Open-Graph-img-paytaps.png" />  
        <link rel="canonical" href={canonicalLink} />    
    </Helmet> 
  )
}

export default SEO
