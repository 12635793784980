export const countries = [
    {abbreviation: 'ad', fullName: "Andorra"},
    {abbreviation: 'ae', fullName: "United Arab Emirates"},
    // {abbreviation: 'af', fullName: "Afghanistan"},
    // {abbreviation: 'ag', fullName: "Antigua & Barbuda"},
    {abbreviation:'ai', fullName: "Anguilla"},
    {abbreviation: 'al', fullName: "Albania"},
    {abbreviation: 'am',  fullName: "Armenia"},
    // {abbreviation: 'ao', fullName: "Angola"},
    {abbreviation: 'aq', fullName: "Antarctica"},
    // {abbreviation: 'ar', fullName: "Argentina"},
    {abbreviation: 'as', fullName: "American Samoa"},
    {abbreviation: 'at', fullName: "Austria"},
    {abbreviation: 'au', fullName: "Australia"},
    // {abbreviation: 'aw', fullName: "Aruba"},
    {abbreviation: 'ax', fullName: "Åland Islands"},
    {abbreviation: 'az', fullName: "Azerbaijan"},
    {abbreviation: 'ba', fullName: "Bosnia & Herzegovina"},
    // {abbreviation: 'bb', fullName: "Barbados"},
    // {abbreviation: 'bd', fullName: "Bangladesh"},
    {abbreviation: 'be', fullName: "Belgium"},
    // {abbreviation: 'bf', fullName: "Burkina Faso"},
    // {abbreviation: 'bg', fullName: "Bulgaria"},
    {abbreviation: 'bh', fullName: "Bahrain"},
    {abbreviation: 'bi', fullName: "Burundi"},
    {abbreviation: 'bj', fullName: "Benin"},
    {abbreviation: 'bl', fullName: "St. Barthélemy"},
    {abbreviation: 'bm', fullName: "Bermuda"},
    {abbreviation: 'bn', fullName: "Brunei"},
    {abbreviation: 'bo', fullName: "Bolivia"},
    {abbreviation: 'bq', fullName: "Caribbean Netherlands"},
    {abbreviation: 'br', fullName: "Brazil"},
    // {abbreviation: 'bs', fullName: "Bahamas"},
    {abbreviation: 'bt', fullName: "Bhutan"},
    {abbreviation: 'bv', fullName: "Bouvet Island"},
    {abbreviation: 'bw', fullName: "Botswana"},
    // {abbreviation: 'by', fullName: "Belarus"},
    // {abbreviation: 'bz', fullName: "Belize"},
    {abbreviation: 'ca', fullName: "Canada"},
    {abbreviation: 'cc', fullName: "Cocos (Keeling) Islands"},
    // {abbreviation: 'cd', fullName: "Congo - Kinshasa"},
    {abbreviation: 'cf', fullName: "Central African Republic"},
    // {abbreviation: 'cg', fullName: "Congo - Brazzaville"},
    {abbreviation: 'ch', fullName: "Switzerland"},
    // {abbreviation: 'ci', fullName: "Côte d’Ivoire"},
    {abbreviation: 'ck', fullName: "Cook Islands"},
    {abbreviation: 'cl', fullName: "Chile"},
    // {abbreviation: 'cm', fullName: "Cameroon"},
    {abbreviation: 'cn', fullName: "China"},
    {abbreviation: 'co', fullName: "Colombia"},
    {abbreviation: 'cr', fullName: "Costa Rica"},
    {abbreviation: 'cu', fullName: "Cuba"},
    {abbreviation: 'cv', fullName: "Cape Verde"},
    {abbreviation: 'cw', fullName: "Curaçao"},
    {abbreviation: 'cx', fullName: "Christmas Island"},
    {abbreviation: 'cy', fullName: "Cyprus"},
    {abbreviation: 'cz', fullName: "Czechia"},
    {abbreviation: 'de', fullName: "Germany"},
    {abbreviation: 'dj', fullName: "Djibouti"},
    {abbreviation: 'dk', fullName: "Denmark"},
    {abbreviation: 'dm', fullName: "Dominica"},
    {abbreviation: 'do', fullName: "Dominican Republic"},
    // {abbreviation: 'dz', fullName: "Algeria"},
    // {abbreviation: 'ec', fullName: "Ecuador"},
    {abbreviation: 'ee', fullName: "Estonia"},
    {abbreviation: 'eg', fullName: "Egypt"},
    {abbreviation: 'eh', fullName: "Western Sahara"},
    {abbreviation: 'er', fullName: "Eritrea"},
    {abbreviation: 'es', fullName: "Spain"},
    {abbreviation: 'et', fullName: "Ethiopia"},
    {abbreviation: 'fi', fullName: "Finland"},
    {abbreviation: 'fj', fullName: "Fiji"},
    {abbreviation: 'fk', fullName: "Falkland Islands"},
    {abbreviation: 'fm', fullName: "Micronesia"},
    {abbreviation: 'fo', fullName: "Faroe Islands"},
    {abbreviation: 'fr', fullName: "France"},
    {abbreviation: 'ga', fullName: "Gabon"},
    {abbreviation: 'gb', fullName: "United Kingdom"},
    {abbreviation: 'gd', fullName: "Grenada"},
    {abbreviation: 'ge', fullName: "Georgia"},
    {abbreviation: 'gf', fullName: "French Guiana"},
    {abbreviation: 'gg', fullName: "Guernsey"},
    {abbreviation: 'gh', fullName: "Ghana"},
    // {abbreviation: 'gi', fullName: "Gibraltar"},
    {abbreviation: 'gl', fullName: "Greenland"},
    {abbreviation: 'gm', fullName: "Gambia"},
    {abbreviation: 'gn', fullName: "Guinea"},
    {abbreviation: 'gp', fullName: "Guadeloupe"},
    {abbreviation: 'gq', fullName: "Equatorial Guinea"},
    {abbreviation: 'gr', fullName: "Greece"},
    {abbreviation: 'gs', fullName: "South Georgia & South Sandwich Islands"},
    {abbreviation: 'gt', fullName: "Guatemala"},
    {abbreviation: 'gu', fullName: "Guam"},
    {abbreviation: 'gw', fullName: "Guinea-Bissau"},
    {abbreviation: 'gy', fullName: "Guyana"},
    {abbreviation: 'hk', fullName: "Hong Kong SAR China"},
    {abbreviation: 'hm', fullName: "Heard & McDonald Islands"},
    {abbreviation: 'hn', fullName: "Honduras"},
    // {abbreviation: 'hr', fullName: "Croatia"},
    // {abbreviation: 'ht', fullName: "Haiti"},
    {abbreviation: 'hu', fullName: "Hungary"},
    {abbreviation: 'id', fullName: "Indonesia"},
    {abbreviation: 'ie', fullName: "Ireland"},
    {abbreviation: 'il', fullName: "Israel"},
    {abbreviation: 'im', fullName: "Isle of Man"},
    {abbreviation: 'in', fullName: "India"},
    {abbreviation: 'io', fullName: "British Indian Ocean Territory"},
    // {abbreviation: 'iq', fullName: "Iraq"},
    // {abbreviation: 'ir', fullName: "Iran"},
    {abbreviation: 'is', fullName: "Iceland"},
    {abbreviation: 'it', fullName: "Italy"},
    {abbreviation: 'je', fullName: "Jersey"},
    // {abbreviation: 'jm', fullName: "Jamaica"},
    // {abbreviation: 'jo', fullName: "Jordan"},
    {abbreviation: 'jp', fullName: "Japan"},
    // {abbreviation: 'ke', fullName: "Kenya"},
    {abbreviation: 'kg', fullName: "Kyrgyzstan"},
    {abbreviation: 'kh', fullName: "Cambodia"},
    {abbreviation: 'ki', fullName: "Kiribati"},
    {abbreviation: 'km', fullName: "Comoros"},
    {abbreviation: 'kn', fullName: "St. Kitts & Nevis"},
    {abbreviation: 'kp', fullName: "North Korea"},
    {abbreviation: 'kr', fullName: "South Korea"},
    {abbreviation: 'kw', fullName: "Kuwait"},
    // {abbreviation: 'ky', fullName: "Cayman Islands"},
    {abbreviation: 'kz', fullName: "Kazakhstan"},
    {abbreviation: 'la', fullName: "Laos"},
    // {abbreviation: 'lb', fullName: "Lebanon"},
    {abbreviation: 'lc', fullName: "St. Lucia"},
    {abbreviation: 'li', fullName: "Liechtenstein"},
    // {abbreviation: 'lk', fullName: "Sri Lanka"},
    {abbreviation: 'lr', fullName: "Liberia"},
    {abbreviation: 'ls', fullName: "Lesotho"},
    {abbreviation: 'lt', fullName: "Lithuania"},
    {abbreviation: 'lu', fullName: "Luxembourg"},
    {abbreviation: 'lv', fullName: "Latvia"},
    {abbreviation: 'ly', fullName: "Libya"},
    {abbreviation: 'ma', fullName: "Morocco"},
    // {abbreviation: 'mc', fullName: "Monaco"},
    {abbreviation: 'md', fullName: "Moldova"},
    {abbreviation: 'me', fullName: "Montenegro"},
    {abbreviation: 'mf', fullName: "St. Martin"},
    {abbreviation: 'mg', fullName: "Madagascar"},
    {abbreviation: 'mh', fullName: "Marshall Islands"},
    {abbreviation: 'mk', fullName: "North Macedonia"},
    // {abbreviation: 'ml', fullName: "Mali"},
    {abbreviation: 'mm', fullName: "Myanmar (Burma)"},
    {abbreviation: 'mn', fullName: "Mongolia"},
    {abbreviation: 'mo', fullName: "Macao SAR China"},
    {abbreviation: 'mp', fullName: "Northern Mariana Islands"},
    {abbreviation: 'mq', fullName: "Martinique"},
    {abbreviation: 'mr', fullName: "Mauritania"},
    {abbreviation: 'ms', fullName: "Montserrat"},
    // {abbreviation: 'mt', fullName: "Malta"},
    {abbreviation: 'mu', fullName: "Mauritius"},
    {abbreviation: 'mv', fullName: "Maldives"},
    {abbreviation: 'mw', fullName: "Malawi"},
    {abbreviation: 'mx', fullName: "Mexico"},
    {abbreviation: 'my', fullName: "Malaysia"},
    // {abbreviation: 'mz', fullName: "Mozambique"},
    // {abbreviation: 'na', fullName: "Namibia"},
    {abbreviation: 'nc', fullName: "New Caledonia"},
    {abbreviation: 'ne', fullName: "Niger"},
    {abbreviation: 'nf', fullName: "Norfolk Island"},
    // {abbreviation: 'ng', fullName: "Nigeria"},
    {abbreviation: 'ni', fullName: "Nicaragua"},
    {abbreviation: 'nl', fullName: "Netherlands"},
    {abbreviation: 'no', fullName: "Norway"},
    {abbreviation: 'np', fullName: "Nepal"},
    {abbreviation: 'nr', fullName: "Nauru"},
    {abbreviation: 'nu', fullName: "Niue"},
    {abbreviation: 'nz', fullName: "New Zealand"},
    {abbreviation: 'om', fullName: "Oman"},
    // {abbreviation: 'pa' , fullName: "Panama"},
    {abbreviation: 'pe', fullName: "Peru"},
    {abbreviation: 'pf', fullName: "French Polynesia"},
    {abbreviation: 'pg', fullName: "Papua New Guinea"},
    // {abbreviation: 'ph', fullName: "Philippines"},
    {abbreviation: 'pk', fullName: "Pakistan"},
    {abbreviation: 'pl', fullName: "Poland"},
    {abbreviation: 'pm', fullName: "St. Pierre & Miquelon"},
    {abbreviation: 'pn', fullName: "Pitcairn Islands"},
    {abbreviation: 'pr', fullName: "Puerto Rico"},
    {abbreviation: 'ps', fullName: "Palestinian Territories"},
    {abbreviation: 'pt', fullName: "Portugal"},
    {abbreviation: 'pw', fullName: "Palau"},
    {abbreviation: 'py', fullName: "Paraguay"},
    {abbreviation: 'qa', fullName: "Qatar"},
    {abbreviation: 're', fullName: "Réunion"},
    {abbreviation: 'ro', fullName: "Romania"},
    {abbreviation: 'rs', fullName: "Serbia"},
    // {abbreviation: 'ru', fullName: "Russia"},
    {abbreviation: 'rw', fullName: "Rwanda"},
    {abbreviation: 'sa', fullName: "Saudi Arabia"},
    {abbreviation: 'sb', fullName: "Solomon Islands"},
    {abbreviation: 'sc', fullName: "Seychelles"},
    // {abbreviation: 'sd', fullName: "Sudan"},
    {abbreviation: 'se', fullName: "Sweden"},
    {abbreviation: 'sg', fullName: "Singapore"},
    {abbreviation: 'sh', fullName: "St. Helena"},
    {abbreviation: 'si', fullName: "Slovenia"},
    {abbreviation: 'sj', fullName: "Svalbard & Jan Mayen"},
    {abbreviation: 'sk', fullName: "Slovakia"},
    {abbreviation: 'sl', fullName: "Sierra Leone"},
    {abbreviation: 'sm', fullName: "San Marino"},
    // {abbreviation: 'sn', fullName: "Senegal"},
    {abbreviation: 'so', fullName: "Somalia"},
    {abbreviation: 'sr', fullName: "Suriname"},
    // {abbreviation: 'ss', fullName: "South Sudan"},
    {abbreviation: 'st', fullName: "São Tomé & Príncipe"},
    // {abbreviation: 'sv', fullName: "El Salvador"},
    {abbreviation: 'sx', fullName: "Sint Maarten"},
    // {abbreviation: 'sy', fullName: "Syria"},
    {abbreviation: 'sz', fullName: "Eswatini"},
    {abbreviation: 'tc', fullName: "Turks & Caicos Islands"},
    {abbreviation: 'td', fullName: "Chad"},
    {abbreviation: 'tf', fullName: "French Southern Territories"},
    {abbreviation: 'tg', fullName: "Togo"},
    {abbreviation: 'th', fullName: "Thailand"},
    {abbreviation: 'tj', fullName: "Tajikistan"},
    {abbreviation: 'tk', fullName: "Tokelau"},
    {abbreviation: 'tl', fullName: "Timor-Leste"},
    {abbreviation: 'tm', fullName: "Turkmenistan"},
    // {abbreviation: 'tn', fullName: "Tunisia"},
    {abbreviation: 'to', fullName: "Tonga"},
    {abbreviation: 'tr', fullName: "Turkey"},
    {abbreviation: 'tt', fullName: "Trinidad & Tobago"},
    {abbreviation: 'tv', fullName: "Tuvalu"},
    {abbreviation: 'tw', fullName: "Taiwan"},
    // {abbreviation: 'tz', fullName: "Tanzania"},
    {abbreviation: 'ua', fullName: "Ukraine"},
    // {abbreviation: 'ug', fullName: "Uganda"},
    {abbreviation: 'um', fullName: "U.S. Outlying Islands"},
    {abbreviation: 'us', fullName: "United States"},
    {abbreviation: 'uy', fullName: "Uruguay"},
    {abbreviation: 'uz', fullName: "Uzbekistan"},
    {abbreviation: 'va', fullName: "Vatican City"},
    {abbreviation: 'vc', fullName: "St. Vincent & Grenadines"},
    // {abbreviation: 've', fullName: "Venezuela"},
    // {abbreviation: 'vg', fullName: "British Virgin Islands"},
    // {abbreviation: 'vi', fullName: "U.S. Virgin Islands"},
    // {abbreviation: 'vn', fullName: "Vietnam"},
    // {abbreviation: 'vu', fullName: "Vanuatu"},
    {abbreviation: 'wf', fullName: "Wallis & Futuna"},
    {abbreviation: 'ws', fullName: "Samoa"},
    // {abbreviation: 'ye', fullName: "Yemen"},
    {abbreviation: 'yt', fullName: "Mayotte"},
    // {abbreviation: 'za', fullName: "South Africa"},
    {abbreviation: 'zm', fullName: "Zambia"},
    {abbreviation: 'zw', fullName: "Zimbabwe"}
  ] 


export const genders = [
    {abbreviation: 'Male', fullName: "Male"},
    {abbreviation: 'Female', fullName: "Female"}
] 

export function monthIndexHandler (month) {
  let index 
  switch(month) {
    case 'Jan' :
      index = 1
      break;
    case 'Feb' : 
      index = 2
      break;
    case 'Mar' :
      index = 3
      break;
    case 'Apr' : 
      index = 4
      break;
    case 'May' : 
      index = 5
      break;
    case 'Jun' : 
      index = 6
      break;
    case 'Jul' :
      index = 7;
      break;
    case 'Aug' : 
      index = 8
      break;
    case 'Sep' : 
      index = 9;
      break;
    case 'Oct' : 
      index = 10;
      break;
    case 'Nov' : 
      index = 11;
      break;
    case 'Dec' : 
      index = 12;
      break;
  }
  return index
}

export const months = [
  {full: 'January', abbreviation: 'Jan', index: 1}, 
  {full: 'February', abbreviation: 'Feb', index: 2}, 
  {full: 'March', abbreviation: 'Mar', index: 3}, 
  {full: 'April', abbreviation: 'Apr', index: 4}, 
  {full: 'May', abbreviation: 'May', index: 5}, 
  {full: 'June', abbreviation: 'Jun', index: 6}, 
  {full: 'July', abbreviation: 'Jul', index: 7}, 
  {full: 'August', abbreviation: 'Aug', index: 8}, 
  {full: 'September', abbreviation: 'Sep', index: 9}, 
  {full: 'October', abbreviation: 'Oct', index: 10}, 
  {full: 'November', abbreviation: 'Nov', index: 11}, 
  {full: 'December', abbreviation: 'Dec', index: 12}
] 
