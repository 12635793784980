import {  API_HOST } from '../config/env';

const API_URL = API_HOST() + "api/";

export function register(payload) {

    return fetch(API_URL+'signup', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function login(payload) {

   return fetch(API_URL+'login', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function saveToken(payloadToken, payloadRefresh) {
    const splittedToken = payloadToken.split('.')                                                                                              
    localStorage.setItem('u_t_h', splittedToken[0])
    localStorage.setItem('u_t_p', splittedToken[1])
    localStorage.setItem('u_t_s', splittedToken[2])
    localStorage.setItem('u_r_t', payloadRefresh) 
}

export function joinToken() {
    if(localStorage.getItem('u_t_h') && localStorage.getItem('u_t_p') && localStorage.getItem('u_t_s')) {
        return [localStorage.getItem('u_t_h'), localStorage.getItem('u_t_p'), localStorage.getItem('u_t_s')].join('.')
    } else {
        return null
    }
}

export function logout() {
     
    return fetch(API_URL+'logout', { 
        method: 'POST', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken()  

        }
    })
}

export function killToken() {
    if(localStorage.getItem('u_t_h') && localStorage.getItem('u_t_p') && localStorage.getItem('u_t_s') && localStorage.getItem('m_t_u') && localStorage.getItem('o_t_p_t') && localStorage.getItem('t_u_f')) {
        localStorage.removeItem('u_t_h')
        localStorage.removeItem('u_t_p')
        localStorage.removeItem('u_t_s')
        localStorage.removeItem('m_t_u')
        localStorage.removeItem('o_t_p_t')
        localStorage.removeItem('t_u_f') 
        localStorage.removeItem('c_l') 
        localStorage.removeItem('m_f_d')
        localStorage.removeItem('m_n_d')
        localStorage.removeItem('t_d_d') 
        localStorage.removeItem('u_r_t')   
        localStorage.removeItem('t_u_f_50_100')
        localStorage.removeItem('t_u_f_100')
        localStorage.removeItem('m_b') 
        localStorage.removeItem('a_f_c')
        localStorage.removeItem('t_c_r')                                  
        return {kill: true}
    } 
    else {
        return null
    }
}

export function requestCard(payload) { 

    return fetch(API_URL+'request-card', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })

}  

export function userBalance() {

    return fetch(API_URL+'client/wallet/balance', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
    
}

export function generateAddress() {
    
    return fetch(API_URL+'address/wallet/generete', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
export function regenerateAddress() {
     
    return fetch(API_URL+'address/wallet/regenerete', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function initialDeposit(payload) {
    
    return fetch(API_URL+'address/wallet/confirmation', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
export function normalDeposit(payload) {
    
    return fetch(API_URL+'request/deposit/confirmation', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function findRequestCard() {
    return fetch(API_URL+'find-request-card', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
} 



export function getProfileData() {

    return fetch(API_URL+'me', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })

}  

export function getCardBalance() {
              
    return fetch(API_URL+'card-balance', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getCardPreviousTransactions() {
     
    return fetch(API_URL+'card-transactions-Previos', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })

} 

export function getCardTransactions() {
     
    return fetch(API_URL+'card-transactions', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })

}
 
export function getCardTopupHistory() {
     
    return fetch(API_URL+'all-request-topup', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    }) 

} 

export function getAllDeposits() {
    
    return fetch(API_URL+'request/deposit', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    }) 
}

export function getConfig() {    
    
    return fetch(API_URL+'config', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function generateOTP() {
    
    return fetch(API_URL+'otp/generate', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function verifyOTP(payload) {

    return fetch(API_URL+'otp/verify-otp', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getcardDetails() {
    
    return fetch(API_URL+'card-details', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function convertToUSDT(payload) {
    return fetch(API_URL+'convert', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function requestTopUp(payload) {
    
    return fetch(API_URL+'request-topup', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function cardInfo() {

    return fetch(API_URL+'card/info', {    
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}  

export function generateOTPForgetPassword(payload) {
    
    return fetch(API_URL+'otp/forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
}

export function verifyOTPForgetPassword(payload) {

    return fetch(API_URL+'otp/verify-otp-forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
}

export function forgetPassword(payload) {
    
    return fetch(API_URL+'forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
}

export function updatePassword(payload) { 

    return fetch(API_URL+'reset-password', {     
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json" 
        }
    })
} 



export function refreshToken(payload) { 

    return fetch(API_URL+'token/refresh', {     
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json" 
        }
    })
}

export function cardApproval(payload) { 

    return fetch(API_URL+'card-approval', {     
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function generateReferralCode() { 

    return fetch(API_URL+'referrals/event/generet_code', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function getReferralCode() { 

    return fetch(API_URL+'referral/code', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })

}

export function getReferralStats() {  

    return fetch(API_URL+'referrals/stats-activity-event', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })

}


export function getAllReferralRewards() {  

    return fetch(API_URL+'referrals/rewards', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })

}

export function getAllInvitedPeople() {  

    return fetch(API_URL+'referrals/people-invited', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
    
}


export function getGeneralReferralRate() {  

    return fetch(API_URL+'referrals/referral-rate-success', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })

}

export function getGlobalReferralDataActivities() {  

    return fetch(API_URL+'referrals/list-acitvity-referral', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
    
} 


export function shareReferralLinkByEmail(payload) {  

    return fetch(API_URL+'referral/share/link', {     
        method: 'POST',
        body: JSON.stringify(payload), 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
    
}

export function getTransactionsOverviewMetrics() {  

    return fetch(API_URL+'metric/transaction_overview', {     
        method: 'GET',
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    }) 

}

export function getTopupRequestsMetrics() {  

    return fetch(API_URL+'metric/topup_requests', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
    
}

export function getDepositRequestsMetrics() {  

    return fetch(API_URL+'metric/deposit_requests', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
    
}

export function verifyEmail(payload) {  

    return fetch(API_URL+'token/verify-email', {     
        method: 'POST',  
        body: JSON.stringify(payload), 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
        }
    })
}

export function resendVerificationEmail(payload) {  

    return fetch(API_URL+'token/resend/verify-email', {     
        method: 'POST',  
        body: JSON.stringify(payload), 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
        }
    })
}

export function securedLogin(payload) { 
    return fetch(API_URL+'log-in', {
         method: 'POST', 
         body: JSON.stringify(payload),
         headers: {
             "Content-Type": "application/json"
         }
     })
} 

export function securedLoginCheck(payload) { 
    return fetch(API_URL+'login-check', {
         method: 'POST', 
         body: JSON.stringify(payload),
         headers: {
             "Content-Type": "application/json"
         }
     })
}  

export function getSecretKey() { 
    return fetch(API_URL+'user/qr/google', {
         method: 'GET',  
         headers: {
             "Content-Type": "application/json", 
             "Accept": "application/json", 
             "Authorization": 'Bearer ' + joinToken() 
         }
     })
}

export function completeLinkToGoogleAuth(payload) { 

    return fetch(API_URL+'user/google', {
         method: 'POST',  
         body: JSON.stringify(payload),  
         headers: {
             "Content-Type": "application/json", 
             "Accept": "application/json", 
             "Authorization": 'Bearer ' + joinToken() 
         }
     })
     
}

export function getLoginType() {
     
    return fetch(API_URL+'user/type/login', {
         method: 'GET',  
         headers: {
             "Content-Type": "application/json", 
             "Accept": "application/json", 
             "Authorization": 'Bearer ' + joinToken() 
         }
     })
    
}



export function getCashBackData() {
     
    return fetch(API_URL+'cash/back', {
         method: 'GET',  
         headers: {
             "Content-Type": "application/json", 
             "Accept": "application/json", 
             "Authorization": 'Bearer ' + joinToken() 
         }
     })
    
} 