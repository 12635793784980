import React, { useState, useRef, useCallback } from 'react'

import { Link, redirect, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup'
import Modal from 'react-bootstrap/Modal'

import TextInput from '../components/TextInput'
import PasswordInput from '../components/PasswordInput'
import Loader from '../../src/jsx/pages/Loader/Loader'
import './login.css'
import { login, formatError, saveToken, joinToken, getConfig  } from '../security/AuthService' 
import { useActive } from '../context/ActiveContext' 
import Button from '../components/Button'
import { useReferral } from '../context/ReferralContext'
import SEO from '../components/SEO' 
import Snowfall from 'react-snowfall'
import SnowFallParent from '../components/SnowFallParent'  
const Login = () => {
    const { handleShowReferral } = useReferral() 
    const error = useRef(null)
    const [show, setShow] = useState(false)
    const navigate = useNavigate()
    const [isError, setIsError] = useState(false)
    const {handleMenuActive} = useActive()

    let state 

    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }

    const handleClose = useCallback(() => setShow(false), []);

    const handleShow = useCallback(() => setShow(true), []);

    const handleShowError = useCallback(()=>{
        setIsError(true)
    }, [])

    const handleCloseError = useCallback(()=>{
        setIsError(false)
    }, [])

    let authResponse
    return (
        <>
        <SEO title={'PayTaps - Your Crypto Payment Solution'} 
            description={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            ogTitle={'PayTaps Crypto Mastercard – Spend Crypto With A Simple Tap'} 
            ogDescription={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            ogUrl={'https://paytaps.com'}  
            twitterTitle={'PayTaps Crypto Mastercard – Spend Crypto With A Simple Tap'} 
            twitterDescription={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            canonicalLink={'https://paytaps.com'}    
        /> 
        <div className='login-container'>
            <SnowFallParent snowflakecount={50} radius={[2.5, 10]} />           
            <div className='login-wrapper'>
                <figure className='login'>
                </figure>
                <div className='login-form-container'>
                    <div className='form-welcoming-part'>
                        <Link to='/'>
                            <img src='/images/registration/Group 1.svg' alt='paytabs logo' draggable='false' />
                        </Link>
                        <div id='welcome-to-paytabs'>Welcome to PayTaps</div>
                        <div id='signup-signin-your-account'>Sign in by entering information below</div> 
                    </div>
                    <>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: ''
                                }}

                                validationSchema={YUP.object({
                                    email: YUP.string().required('Required!').email('Invalid Email!'),
                                    password: YUP.string().required('Required!')
                                    
                                })}

                                onSubmit={ async (values, {setSubmitting, resetForm})=>{
                                    
                                    try {
                                        if(isError) {
                                            handleCloseError()
                                        }
                                        
                                        handleShow()
                                        const response = await login({email: values.email, password: values.password}) 
                                        authResponse = await response.json()
                                             
                                        if(response.ok && authResponse.token) {
                                            
                                            handleCloseError()
                                            saveToken(authResponse.token, authResponse.refresh_token) 
                                            const config_result = await getConfig()
                                            const config_data = await config_result.json()
                                            if(config_result.ok) {
                                                for (let param of config_data['data']) {
                                                    if(param['name'] === 'CreditLimit') {
                                                        localStorage.setItem('c_l', param['value'])
                                                    }

                                                    if(param['name'] === 'MinBalance') {
                                                        localStorage.setItem('m_b', param['value']) 
                                                    }

                                                    if(param['name'] === 'TimeDownOTP') {
                                                        localStorage.setItem('o_t_p_t', param['value'])
                                                    }

                                                    if(param['name'] === 'MinTopup')  {
                                                        localStorage.setItem('m_t_u', param['value'])
                                                    }

                                                    if(param['name'] === 'TopupFee')  { 
                                                        localStorage.setItem('t_u_f', param['value'])
                                                    }

                                                    if(param['name'] === 'MinFirstDeposit') {
                                                        localStorage.setItem('m_f_d', param['value'])
                                                    }

                                                    if(param['name'] === 'MinDeposit')  {
                                                        localStorage.setItem('m_n_d', param['value'])
                                                    }

                                                    if(param['name'] === 'TimeDownDeposite')  {
                                                        localStorage.setItem('t_d_d', param['value'])
                                                    }

                                                    if(param['name'] === 'TopupFee50_100') {
                                                        localStorage.setItem('t_u_f_50_100', param['value'])
                                                    }

                                                    if(param['name'] === 'TopupFee100') {
                                                        localStorage.setItem('t_u_f_100', param['value']) 
                                                    }
                                                }  
                                                  
                                                
                                            } else {
                                                localStorage.setItem('c_l', '100000') 
                                                localStorage.setItem('o_t_p_t', '1')  
                                                localStorage.setItem('m_t_u', '100')
                                                localStorage.setItem('t_u_f', '3') 
                                                localStorage.setItem('m_f_d', '120')
                                                localStorage.setItem('m_n_d', '100')
                                                localStorage.setItem('t_d_d', '10')
                                                localStorage.setItem('t_u_f_50_100', '4')
                                                localStorage.setItem('t_u_f_100', '3')     
                                            }
                                                                                            
                                            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))
                                            
                                            setSubmitting(false)
                                            resetForm()
                                            // console.log(decoded_payload['req']) 
                                            switch(decoded_payload['req']) {
                                                
                                                case 'N': { handleMenuActive('User Guide'); navigate('/userguide') }                     
                                                        break;
                                                case 'P': { handleMenuActive(''); navigate('/thankyouforcardorder') } 
                                                        break; 
                                                case 'D': { handleMenuActive('User Guide'); navigate('/userguide') }                          
                                                        break;
                                                case 'S': {handleShowReferral();handleMenuActive('Dashboard');navigate('/dashboard')} 
                                                        break;
                                                default:  navigate('/ordercard')
                                            } 
                                            handleClose()
                                        }
                                        else if(!response.ok && authResponse.error) {
                                            error.current = formatError(authResponse.message)
                                            handleShowError()
                                                                } 
                                        
                                    } catch (err) {
                                        // console.log(err)
                                    }

                                }}
                            >
                                {(formik) => {

                                state = formik
                                
                                return (

                                    <Form className='form' onSubmit={formik.handleSubmit}>

                                        <TextInput inputtype='email' name='email' id='email' label='Email' error={formik.errors.email} touched={formik.touched.email} onKeyUp={keyUpHandler} />

                                        <PasswordInput name='password' id='password' label='Password' error={formik.errors.password} touched={formik.touched.password} onKeyUp={keyUpHandler} />
                                        <Link to='/resetpassword' className='forgotPassword'>Forgot Password</Link>

                                        <button type='submit' id='sign-in' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Sign In</button> 

                                    </Form>)}}
                            </Formik>
                    </>
                    <div className='already-have-account'>
                        Don't have an account?
                        <Link to='/register' style={{
                            fontWeight: '700',
                            color: '#0E0E0E'
                        }}>  Sign up </Link>
                    </div> 
                </div>
            </div>
        </div> 
    

        <Modal show={show} className='loader-error' backdrop='static' keyboard='false'>
            <Modal.Body className='center'>
                {!isError && <Loader />}
                {isError && <div className='errorMessageContainer'> <figure><img src='/images/registration/Reset password-rafiki.svg' width='300' /></figure><div>Invalid credentials</div><div className='messageError'>The email address or password you entered is incorrect. Please check your details and try again. </div> <Button styles= 'try-again-btn' click={handleClose}>Try Again!</Button></div>}  
            </Modal.Body>
        </Modal>

    </>

)
}

export default Login










