// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#topupRW {
    --height: 351px; 
    overflow-y: scroll;
    height: calc(var(--height) + var(--mHeight, 0px));  
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/topupvisualizer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,iDAAiD;AACrD","sourcesContent":["#topupRW {\n    --height: 351px; \n    overflow-y: scroll;\n    height: calc(var(--height) + var(--mHeight, 0px));  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
