import React, { useEffect } from "react";

import { useToggle } from "../../../context/ToggleContext";
export function  NavMenuToggle(){
   
	setTimeout(()=>{	
		let mainwrapper = document.querySelector("#main-wrapper");
      if(mainwrapper) {
         if(mainwrapper.classList.contains('menu-toggle')){
            mainwrapper.classList.remove("menu-toggle");
         }else{
            mainwrapper.classList.add("menu-toggle");
         }
      }
		
	},10);
   
}
function closeMenu () {
   setTimeout(()=>{
      let mainwrapper = document.querySelector("#main-wrapper");
            if(mainwrapper) {
               if(mainwrapper.classList.contains('menu-toggle')){
                  mainwrapper.classList.remove("menu-toggle");
               } 
            } 
   }, 10)
}
const NavHader = () => {
   useEffect(()=>{
      let deznav = document.querySelector('.deznav')

      window.addEventListener('click', (e)=>{
         if(window.innerWidth < 1024) {
         
            if(!deznav.contains(e.target) && (document.querySelector('[alt="paytaps-icon-logo"]') !== e.target)) {
               closeMenu() 
            }
      }
      })

   }, [])
   const { toggle, toggleHandler } = useToggle()
   return (
      <div className="nav-header">
         
         <div className='togglerContainerStyles' >
            <img src="/images/landingpage/header/paytaps xmas 198.svg"  alt="paytaps-main-logo" onClick={toggleHandler} style={toggle ? {cursor: 'pointer', display: 'none'} : {cursor: 'pointer', width: '76%', marginLeft: '-41px'}} className="toggler-trigger" />   
            <img src="/images/landingpage/header/paytaps xmas 43.svg" alt="paytaps-icon-logo" onClick={toggleHandler} style={!toggle ? {height: '52px', cursor: 'pointer', display: 'none'} : {height: '52px', cursor: 'pointer'}} className="toggler-trigger" />
         </div>
      </div>
   );
};

export default NavHader;

