import React, { useState, useEffect, useRef } from 'react'  
import TransferBarGraph from './../jsx/components/Mophy/Dashboard/TransferBarGraph';
import './CSS/transactionoverview.css' 
import { getTransactionsOverviewMetrics } from '../security/AuthService'; 
import Loader from '../jsx/pages/Loader/Loader'
import { monthIndexHandler, months } from '../utils/utils'; 
const TransactionOverview = () => {
  const [illustrationHeight, setIllustrationHeight] = useState(
    (window.innerWidth >= 1496) ? '350': 
    ((window.innerWidth < 1496 && window.innerWidth > 768) ? '450' :
    ((window.innerWidth <= 768 && window.innerWidth > 480) ? '375' : '400')  
  ))

  const [filterByMonth, setFilterByMonth] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']) 

  const [loadingState, setLoadingState] = useState(true) 

  const [transactionOverviewMetrcis, setTransactionOverviewMetrcis] = useState({
    allDepositsPerMonth: new Array(12).fill(0),
    allTopupPerMonth: new Array(12).fill(0)
  })   

  const transactionOverviewMetrcisRef = useRef()

  useEffect(()=>{
    getTransactionsOverviewDataMetrics()
  }, []) 

  async function getTransactionsOverviewDataMetrics () {
    try {
      const fetch_trx_overview_metrics = await getTransactionsOverviewMetrics()
      const result_fetch_trx_overview_metrics = await fetch_trx_overview_metrics.json()
      if((fetch_trx_overview_metrics.status === 200) && (result_fetch_trx_overview_metrics['message'] === 'Success')) { 
        setTransactionOverviewMetrcis({
          ...transactionOverviewMetrcis,
          allDepositsPerMonth: result_fetch_trx_overview_metrics['data']['totalAmountDepositbyMonth'].flatMap((item)=>item.value),
          allTopupPerMonth: result_fetch_trx_overview_metrics['data']['totalAmountTopupbyMonth'].flatMap((function(item) {
            if(item.value.totalAmountFiat) {
              return Math.floor(item.value.totalAmountFiat) 
            } else {
              return 0
            } 
          })) 
        }) 
        transactionOverviewMetrcisRef.current =  {
          allDepositsPerMonth: result_fetch_trx_overview_metrics['data']['totalAmountDepositbyMonth'].flatMap((item)=>item.value),
          allTopupPerMonth: result_fetch_trx_overview_metrics['data']['totalAmountTopupbyMonth'].flatMap((function(item) {
            if(item.value.totalAmountFiat) {
              return Math.floor(item.value.totalAmountFiat) 
            } else {
              return 0
            } 
          })) 
        } 
        setLoadingState(false) 
      }
    } catch(err) { 

    }
  }

  function filterByMonthHandler (e, month) { 
    const index = monthIndexHandler(e.currentTarget.value)

    if(!index) {
      setFilterByMonth(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])   
      return setTransactionOverviewMetrcis({  
        ...transactionOverviewMetrcis,
        allDepositsPerMonth: transactionOverviewMetrcisRef.current.allDepositsPerMonth,
        allTopupPerMonth: transactionOverviewMetrcisRef.current.allTopupPerMonth
      }) 
    }

    setTransactionOverviewMetrcis({
      allDepositsPerMonth: [transactionOverviewMetrcisRef.current.allDepositsPerMonth[index - 1]],
      allTopupPerMonth: [transactionOverviewMetrcisRef.current.allTopupPerMonth[index - 1]]                                      
  })
 
  setFilterByMonth([e.currentTarget.value]) 
}

  return (     
    <>
      <div className='transactionOverviewContainer'>
        {!loadingState && <>
          <div className='transactionOverviewTitle'>Transaction Overview</div>
          <TransferBarGraph illustrationHeight={illustrationHeight} transactionOverviewMetrcis={transactionOverviewMetrcis} listMonths={filterByMonth} />    
          <>
            <select onChange={(e)=>filterByMonthHandler(e)} id='monthlyFilterdData'>
              <option value='showAll'>Show All</option> 
              {months.map((month, index)=><option key={month.full} value={month.abbreviation} >{month.full}</option>)} 
            </select>
          </>
        </>} 
        {loadingState && <div className='waitLoadingProfileData h-50vh'><div>Please wait...</div><Loader /></div>}
      </div>
    </> 
  )
}

export default TransactionOverview   