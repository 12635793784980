import React from 'react'
import InviteShareLink from './InviteShareLink'
import Spending from './Spendings'
import './CSS/invitesharelinksspendings.css'
const InviteShareLinkSpendings = ({referralCode, codeExpirationDate}) => {
  
  return (
    <div className='inviteShareLinksSpendingsContainer'>
        <InviteShareLink referralCode={referralCode} codeExpirationDate={codeExpirationDate} />  
        <Spending />  
    </div>
  )
}

export default InviteShareLinkSpendings