// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .allTransactionsHistory .transactions-wrapper-body, .allTransactionsHistory #topupRW {
    height: 461px; 
} */

.cashBackHistoryContainer {
    width: 100%;
    background: #FFFFFF;
    padding-top: 15px;
    padding-bottom: 30px;
    margin-top: 27px;
}

@media screen and (max-width: 640px) {
    .cashBackHistoryContainer {
        overflow-x: scroll; 
    }
    .cashBackHistoryContainer::-webkit-scrollbar {
        height: 4px;
    }
    .cashBackHistoryContainer::-webkit-scrollbar-thumb {
        background: #1eaae7;
        border-radius: 5px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/CSS/transactionshistory.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,WAAW;IACf;IACA;QACI,mBAAmB;QACnB,kBAAkB;IACtB;AACJ","sourcesContent":["/* .allTransactionsHistory .transactions-wrapper-body, .allTransactionsHistory #topupRW {\n    height: 461px; \n} */\n\n.cashBackHistoryContainer {\n    width: 100%;\n    background: #FFFFFF;\n    padding-top: 15px;\n    padding-bottom: 30px;\n    margin-top: 27px;\n}\n\n@media screen and (max-width: 640px) {\n    .cashBackHistoryContainer {\n        overflow-x: scroll; \n    }\n    .cashBackHistoryContainer::-webkit-scrollbar {\n        height: 4px;\n    }\n    .cashBackHistoryContainer::-webkit-scrollbar-thumb {\n        background: #1eaae7;\n        border-radius: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
