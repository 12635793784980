import { getConfig } from "../security/AuthService";

export const LocalStorage = async (navigate, handleMenuActive, handleShowReferral) => {
  console.log("dd");

const config_result = await getConfig();
const config_data = await config_result.json();
console.log(config_result);

if (config_result.ok) {
  for (let param of config_data["data"]) {
    if (param["name"] === "CreditLimit") {
      localStorage.setItem("c_l", param["value"]);
    }

    if (param["name"] === "MinBalance") {
      localStorage.setItem("m_b", param["value"]);
    }

    if (param["name"] === "TimeDownOTP") {
      localStorage.setItem("o_t_p_t", param["value"]);
    }

    if (param["name"] === "MinTopup") {
      localStorage.setItem("m_t_u", param["value"]);
    }

    if (param["name"] === "TopupFee") {
      localStorage.setItem("t_u_f", param["value"]);
    }

    if (param["name"] === "MinFirstDeposit") {
      localStorage.setItem("m_f_d", param["value"]);
    }

    if (param["name"] === "MinDeposit") {
      localStorage.setItem("m_n_d", param["value"]);
    }

    if (param["name"] === "TimeDownDeposite") {
      localStorage.setItem("t_d_d", param["value"]);
    }

    if (param["name"] === "TopupFee50_100") {
      localStorage.setItem("t_u_f_50_100", param["value"]);
    }

    if (param["name"] === "TopupFee100") {
      localStorage.setItem("t_u_f_100", param["value"]);
    }

    if (param["name"] === "TokenCixRatio") {
      localStorage.setItem("t_c_r", param["value"]);
    }

    if (param["name"] === "ActiveFeesCix") {
      localStorage.setItem("a_f_c", param["value"]);
    }

    if (param["name"] === "ActiveCashBack") {
      localStorage.setItem("a_c_b", param["value"]);
    }
  }
} else {
  localStorage.setItem("c_l", "100000");
  localStorage.setItem("o_t_p_t", "1");
  localStorage.setItem("m_t_u", "100");
  localStorage.setItem("t_u_f", "3");
  localStorage.setItem("m_f_d", "120");
  localStorage.setItem("m_n_d", "100");
  localStorage.setItem("t_d_d", "10");
  localStorage.setItem("t_u_f_50_100", "4");
  localStorage.setItem("t_u_f_100", "3");
  localStorage.setItem("t_c_r", "0.0045");
  localStorage.setItem("a_f_c", "false");
  localStorage.setItem("a_c_b", "false");
}
const decoded_payload = JSON.parse(atob(localStorage.getItem("u_t_p")));
console.log(decoded_payload);
switch (decoded_payload["req"]) {
  case "N":
    {
      handleMenuActive("User Guide");
      navigate("/userguide");
    }
    break;
  case "P":
    {
      handleMenuActive("");
      navigate("/thankyouforcardorder");
    }
    break;
  case "D":
    {
      handleMenuActive("User Guide");
      navigate("/userguide");
    }
    break;
  case "S":
    {
      handleShowReferral();
      handleMenuActive("Dashboard");
      navigate("/dashboard");
    }
    break;
  default:
    navigate("/ordercard");
}
}