// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchAreaContainer {
    width: 100%; 
    padding-inline: 15px; 
    position: relative;
    top: 15px; 
}
#filterCardTrxByName {
    width: 100%;
    height: 27px;
    margin-top: 8px; 
    padding-inline: 30px;
    border: 1px solid #868383;
    border-radius: 27px;    
    font-size: 15px;
    line-height: 1;
    font-weight: 400;
    color: #382d2d; 
    transition: 0.5s box-shadow, border-color;                                      
}
#filterCardTrxByName:focus {
    border-color: #000000; 
    box-shadow: 0px 0px 16px 0 #d3d2d2; 
}
.mgStyles {
    width: 20px; 
    position: absolute;
    top: 12px;
    left: 25px;
}
.cmStyles {
    position: absolute;
    top: 11px;
    right: 25px;
}
.cmStyles > img {
    width: 20px;
} 

@media screen and (max-width: 640px) {
    .searchAreaContainer {
        width: 700px; 
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CSS/searcharea.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oBAAoB;IACpB,kBAAkB;IAClB,SAAS;AACb;AACA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,oBAAoB;IACpB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,yCAAyC;AAC7C;AACA;IACI,qBAAqB;IACrB,kCAAkC;AACtC;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;AACA;IACI,WAAW;AACf;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".searchAreaContainer {\n    width: 100%; \n    padding-inline: 15px; \n    position: relative;\n    top: 15px; \n}\n#filterCardTrxByName {\n    width: 100%;\n    height: 27px;\n    margin-top: 8px; \n    padding-inline: 30px;\n    border: 1px solid #868383;\n    border-radius: 27px;    \n    font-size: 15px;\n    line-height: 1;\n    font-weight: 400;\n    color: #382d2d; \n    transition: 0.5s box-shadow, border-color;                                      \n}\n#filterCardTrxByName:focus {\n    border-color: #000000; \n    box-shadow: 0px 0px 16px 0 #d3d2d2; \n}\n.mgStyles {\n    width: 20px; \n    position: absolute;\n    top: 12px;\n    left: 25px;\n}\n.cmStyles {\n    position: absolute;\n    top: 11px;\n    right: 25px;\n}\n.cmStyles > img {\n    width: 20px;\n} \n\n@media screen and (max-width: 640px) {\n    .searchAreaContainer {\n        width: 700px; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
