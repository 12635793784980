import React, { useState, useRef, useEffect } from 'react'           
import './CSS/transactionshistory.css'  
import TopUpVisualizer from '../components/TopUpVisualizer'  
import moment from 'moment';   
import { getAllDeposits, getCardTopupHistory, findRequestCard, getCashBackData } from '../security/AuthService';  
import Loader from '../jsx/pages/Loader/Loader';    
import DepositVisualizer from '../components/DepositVisualizer';
import { useReferral } from '../context/ReferralContext'; 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 
import { useNavigate } from 'react-router-dom'
import CashBackVisualizer from '../components/CashBackVisualizer'; 
const TransactionsHistory = () => { 
    const navigate = useNavigate()  
    const [depositHistory, setDepositHistory] = useState({data: []}) 
    const [topupHistory, setTopupHistory] = useState({data: []}) 
    const [cashBackHistory, setCashBackHistory] = useState({data: []}) 
    const [filteredDepositHistory, setFilteredDepositHistory] = useState({data: []}) 
    const [filteredTopupHistory, setFilteredTopupHistory] = useState({data: []})
    const [filteredCashBackHistory, setFilteredCashBackHistory] = useState({data: []})
    const activeFilterPrevTrx = useRef(null)  
    const activeFilterTopUp = useRef(null) 
    const activeFilterCashBack = useRef(null) 
    const [isLoading, setIsLoading] = useState(true) 
    const { showReferral, handleShowReferral } = useReferral()  

    useEffect(()=>{
        getAllTransactionsHistory() 
    }, [])  

    async function getAllTransactionsHistory () {
        try {
            setIsLoading(true) 
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 

            if(decoded_payload['req'] !== 'S') {
                let fetch_card_request = {
                    api_call: await findRequestCard() 
                }
                // If fetch returns 401 
                if(fetch_card_request.api_call.status === 401) {
                    const renewFCR = async ()=>{
                        return await findRequestCard()
                    }
                    if(await refreshTokenWithAPICallHandler(fetch_card_request, renewFCR) === 'REDIRECT_TO_LOGIN') {
                        return navigate('/login')  
                    }
                }
                const result_fetch_card_request = await fetch_card_request.api_call.json() 
                
                if(result_fetch_card_request['data'].length > 0) {
                    if(result_fetch_card_request['data'][0]['requesteStatus'] === 'Success') {
                        handleShowReferral() 
                    }
                }
            } else {
                if(!showReferral) {
                    handleShowReferral() 
                }
            } 

            let all_deposits = {
                api_call: await getAllDeposits()
            }
            // If fetch returns 401
            if(all_deposits.api_call.status === 401) {
                const renewALD = async ()=>{
                    return await getAllDeposits()
                }
                if(await refreshTokenWithAPICallHandler(all_deposits, renewALD) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const all_deposits_result = await all_deposits.api_call.json()  

            let all_topup = {
                api_call: await getCardTopupHistory()
            }
            // If fetch returns 401
            if(all_topup.api_call.status === 401) {
                const renewCTH = async ()=>{
                    return await getCardTopupHistory()
                }
                if(await refreshTokenWithAPICallHandler(all_topup, renewCTH) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const all_topup_result = await all_topup.api_call.json() 

            const fetch_cashbackdata = await getCashBackData()
            const cashbackdata_result = await fetch_cashbackdata.json() 

            if(all_deposits.api_call.ok && all_topup.api_call.ok && fetch_cashbackdata) {  
                setDepositHistory({...depositHistory, data: all_deposits_result['data'].reverse()}); 
                setFilteredDepositHistory({...filteredDepositHistory, data: all_deposits_result['data']})    
                setTopupHistory({...topupHistory, data: all_topup_result['data']}); 
                setFilteredTopupHistory({...filteredTopupHistory, data: all_topup_result['data']})
                setCashBackHistory({...cashBackHistory, data: cashbackdata_result['data'].reverse()}) 
                setFilteredCashBackHistory({...filteredCashBackHistory, data: cashbackdata_result['data']})   
            } 
        } catch(err) {

        } finally {
            setIsLoading(false) 
        }
         
    }

    function activeFilterPrevTrxHandler (e) {

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterPrevTrx.current = null 
        } else {
            activeFilterPrevTrx.current = e.target.innerHTML  
        }  
        handleFilterDepositHistory() 

    }

    function activeFilterTopUpHandler (e) { 

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterTopUp.current = null
        } else {
            activeFilterTopUp.current = e.target.innerHTML 
        }
        handleFilterTopupHistory()  

    } 

    function activeFilterCashBackHandler (e) { 

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterCashBack.current = null
        } else {
            activeFilterCashBack.current = e.target.innerHTML 
        }
        handleFilterCashBackHistory()  

    }

    function handleFilterDepositHistory() {
        
        if(activeFilterPrevTrx.current==='Current Month') {
            const filteredData = depositHistory['data'].filter((trx)=> (((moment().month() + 1) === moment(trx?.createdAt?.date).month() + 1) && ((moment(trx?.createdAt?.date).year()) === moment().year() )))    
            return  setFilteredDepositHistory({...filteredDepositHistory, data: filteredData})
               
        } else if(activeFilterPrevTrx.current === 'Current Week') {
            const filteredData = depositHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('week').valueOf())))     
            return  setFilteredDepositHistory({...filteredDepositHistory, data: filteredData}) 
        } else if(activeFilterPrevTrx.current === 'Today') {
            const filteredData = depositHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf() >= moment().startOf('day')) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('day'))))   
            return  setFilteredDepositHistory({...filteredDepositHistory, data: filteredData})  
        } else {

            return setFilteredDepositHistory({data: depositHistory['data']})  
        } 
    }

    function handleFilterTopupHistory() {
        
        if(activeFilterTopUp.current==='Current Month') {
            const filteredData = topupHistory["data"].filter((trx)=> (((moment().month() + 1) === moment(trx?.createdAt?.date).month() + 1) && ((moment(trx?.createdAt?.date).year()) === moment().year() )))    
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})
            
        } else if(activeFilterTopUp.current === 'Current Week') {
            const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('week').valueOf())))     
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData}) 
        } else if(activeFilterTopUp.current === 'Today') {
            const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf() >= moment().startOf('day')) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('day'))))  
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})  
        } else {

            return setFilteredTopupHistory({data: topupHistory['data']})  
        } 
    }

    function handleFilterCashBackHistory () {
        
        if(activeFilterCashBack.current==='Current Month') {
            const filteredData = cashBackHistory["data"].filter((trx)=> (((moment().month() + 1) === moment(trx?.date?.date).month() + 1) && ((moment(trx?.date?.date).year()) === moment().year() )))    
            return  setFilteredCashBackHistory({...filteredCashBackHistory, data: filteredData})
            
        } else if(activeFilterCashBack.current === 'Current Week') {
            const filteredData = cashBackHistory['data'].filter((trx)=> ((moment(trx?.date?.date).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx?.date?.date).valueOf() <= moment().endOf('week').valueOf())))     
            return  setFilteredCashBackHistory({...filteredCashBackHistory, data: filteredData}) 
        } else if(activeFilterCashBack.current === 'Today') {
            const filteredData = cashBackHistory['data'].filter((trx)=> ((moment(trx?.date?.date).valueOf() >= moment().startOf('day')) && (moment(trx?.date?.date).valueOf() <= moment().endOf('day'))))  
            return  setFilteredCashBackHistory({...filteredCashBackHistory, data: filteredData})  
        } else {

            return setFilteredCashBackHistory({data: cashBackHistory['data']})  
        } 

    }

  return (
    

    <>
        {!isLoading && <><div className='allTransactionsHistory'>  
                            <section className='history-data-container'>
                                <div className='previous-transactions-wrapper'>
                                    <div className='previous-transactions-wrapper-header transactionsHistoryPreviousDepositsHeader'>
                                        <div className='previous-transactions-wrapper-header-text-content'>
                                            <span>Deposit History </span>
                                            {(depositHistory['data'].length > 0) && 
                                            <>
                                            {(activeFilterPrevTrx.current === 'Current Month') && <span>Current month's transactions</span>}
                                            {(activeFilterPrevTrx.current === 'Current Week') && <span>Current Week's transactions</span>}
                                            {(activeFilterPrevTrx.current === 'Today') && <span>Today's transactions</span>}
                                            {(activeFilterPrevTrx.current === null) && <span>All Deposit History</span>}                     
                                            </>}
                                        </div>
                                        {(depositHistory['data'].length > 0) && <div className='previous-transactions-wrapper-header-filter'>
                                            <button className={`${activeFilterPrevTrx.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler} >Current Month</button>
                                            <button className={`${activeFilterPrevTrx.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Current Week</button>
                                            <button className={`${activeFilterPrevTrx.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Today</button>  
                                            {((activeFilterPrevTrx.current === 'Current Month' || activeFilterPrevTrx.current === 'Current Week') || activeFilterPrevTrx.current === 'Today') && <button className='clearFilter' onClick={activeFilterPrevTrxHandler}><span>X</span> <span>Clear Filter</span></button>} 
                                        </div>}
                                    </div>
                                    
                                <DepositVisualizer data={filteredDepositHistory['data']} />      

                                </div>
                                {showReferral &&<div className='top-up-history-wrapper'>
                                    <div className='previous-transactions-wrapper-header transactionsHistoryPreviousTopupHeader'>
                                        <div className='previous-transactions-wrapper-header-text-content'>
                                            <span>Top-up History  </span>
                                            {(topupHistory['data'].length > 0) && 
                                            <>
                                            {(activeFilterTopUp.current === 'Current Month') && <span>Current month's transactions</span>}
                                            {(activeFilterTopUp.current === 'Current Week') && <span>Current Week's transactions</span>}
                                            {(activeFilterTopUp.current === 'Today') && <span>Today's transactions</span>}
                                            {(activeFilterTopUp.current === null) && <span>All Top-up History</span>}                   
                                            </>}
                                        </div>
                                        {(topupHistory['data'].length > 0) &&<div className='previous-transactions-wrapper-header-filter'>
                                            <button className={`${activeFilterTopUp.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler} >Current Month</button>
                                            <button className={`${activeFilterTopUp.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Current Week</button>
                                            <button className={`${activeFilterTopUp.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Today</button> 
                                            {((activeFilterTopUp.current === 'Current Month' || activeFilterTopUp.current === 'Current Week') || activeFilterTopUp.current === 'Today') && <button className='clearFilter' onClick={activeFilterTopUpHandler}><span>X</span> <span>Clear Filter</span></button>}          
                                        </div>} 
                                    </div>
                                    
                                    <TopUpVisualizer data={filteredTopupHistory['data']} />        

                                </div>} 
                                
                            </section>
                       </div>

                       {/* {showReferral && <div>
                            <div className='cashBackHistoryContainer'>
                                <div className='previous-transactions-wrapper-header transactionsHistoryPreviousTopupHeader'>
                                    <div className='previous-transactions-wrapper-header-text-content'>
                                        <span>Cash Back History  </span> 
                                        {(cashBackHistory['data'].length > 0) && 
                                        <>
                                        {(activeFilterCashBack.current === 'Current Month') && <span>Current month's transactions</span>}
                                        {(activeFilterCashBack.current === 'Current Week') && <span>Current Week's transactions</span>}
                                        {(activeFilterCashBack.current === 'Today') && <span>Today's transactions</span>}
                                        {(activeFilterCashBack.current === null) && <span>All Cash Back History</span>}                    
                                        </>}
                                    </div>
                                    {(cashBackHistory['data'].length > 0) &&<div className='previous-transactions-wrapper-header-filter'>
                                        <button className={`${activeFilterCashBack.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterCashBackHandler} >Current Month</button>
                                        <button className={`${activeFilterCashBack.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterCashBackHandler}>Current Week</button>
                                        <button className={`${activeFilterCashBack.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterCashBackHandler}>Today</button> 
                                        {((activeFilterCashBack.current === 'Current Month' || activeFilterCashBack.current === 'Current Week') || activeFilterCashBack.current === 'Today') && <button className='clearFilter' onClick={activeFilterCashBackHandler}><span>X</span> <span>Clear Filter</span></button>}          
                                    </div>} 
                                </div>
                                
                                <CashBackVisualizer data={filteredCashBackHistory['data']} />                                                

                            </div>
                       </div>} */}
                       </>
        }  


    {isLoading && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
    </>
  )
}

export default TransactionsHistory 





                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  