import React from 'react'
import LpHeader from './LPHeader/LpHeader'
import LpBody from './LPBody/LpBody'
import LpFooter from './LPFooter/LpFooter'
import SEO from '../components/SEO'  
import './lplayout.css'
import Snowfall from 'react-snowfall'
import SnowFallParent from '../components/SnowFallParent' 
const Lplayout = () => {
  
  return (
    <>

      <SEO title={'PayTaps - Your Crypto Payment Solution'} 
           description={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
           ogTitle={'PayTaps Crypto Mastercard – Spend Crypto With A Simple Tap'} 
           ogDescription={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
           ogUrl={'https://paytaps.com'}  
           twitterTitle={'PayTaps Crypto Mastercard – Spend Crypto With A Simple Tap'} 
           twitterDescription={'PayTaps Crypto Mastercard: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
           canonicalLink={'https://paytaps.com'}   
      />  
      <LpHeader />
      <main>
          <LpBody />
      </main>
      <LpFooter />
      <SnowFallParent snowflakecount={50} radius={[2.5, 10]} /> 
    </>
  )
}

export default Lplayout